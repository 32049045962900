import * as React from "react";
import { api } from "lib";
import { UserModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  action: string;
  status: string;
  error: string;
  user: UserModel | null;
}

interface Props {
  updateUser: State;
  update: (user: UserModel) => void;
  archive: (user: UserModel) => void;
  unarchive: (user: UserModel) => void;
}

export const userUpdateActions = {
  archive: "ARCHIVE",
  update: "UPDATE",
  unarchive: "UNARCHIVE",
} as const;

const idleState = {
  action: "",
  status: api.idle,
  error: "",
  user: null,
};

const loadingState = (action: string) => ({
  action: action,
  status: api.loading,
  error: "",
  user: null,
});

const errorState = (action: string, error: string) => ({
  action: action,
  status: api.error,
  error: error,
  user: null,
});

const successState = (action: string, user: UserModel) => ({
  action: action,
  status: api.success,
  error: "",
  user: user,
});

export const useUpdateUser = (): Props => {
  const [state, setState] = React.useState<State>(idleState);

  const archive = React.useCallback(async (user: UserModel) => {
    setState(loadingState(userUpdateActions.archive));
    const resp = await api.delete(`/api/users/${user.id}`);
    if (resp.status === StatusCodes.OK) {
      setState(successState(userUpdateActions.archive, resp.body));
    } else {
      setState(errorState(userUpdateActions.archive, resp.body.error));
    }
  }, []);

  const unarchive = React.useCallback(async (user: UserModel) => {
    setState(loadingState(userUpdateActions.unarchive));
    const resp = await api.put(`/api/users/${user.id}`, { user: { status: "active" } });
    if (resp.status === StatusCodes.OK) {
      setState(successState(userUpdateActions.unarchive, resp.body));
    } else {
      setState(errorState(userUpdateActions.unarchive, resp.body.error));
    }
  }, []);

  const update = React.useCallback(async (updatedUser: UserModel) => {
    setState(loadingState(userUpdateActions.update));
    const payload = {
      user: {
        first_name: updatedUser.first_name,
        last_name: updatedUser.last_name,
        email: updatedUser.email ? updatedUser.email : null,
        username: updatedUser.username ? updatedUser.username : null,
        hide_as_employee: updatedUser.hide_as_employee ? updatedUser.hide_as_employee : false,
      },
    };
    const resp = await api.put(`/api/users/${updatedUser.id}`, payload);
    if (resp.status === StatusCodes.OK) {
      setState(successState(userUpdateActions.update, resp.body));
    } else {
      setState(errorState(userUpdateActions.update, resp.body.error));
    }
  }, []);

  return {
    updateUser: state,
    update,
    archive,
    unarchive,
  };
};

export const formatCurrency = (amount: number | undefined, fraction = true) => {
  if (!amount) {
    amount = 0;
  }

  return (Math.round(amount * 100) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fraction ? 2 : 0,
    maximumFractionDigits: fraction ? 2 : 0,
  });
};

export const formatPercentage = (amount: number | undefined) => {
  if (!amount) {
    amount = 0;
  }
  return `${(amount * 100).toFixed(2)}%`;
};

export const calcDiscount = (amount: number, discount: number) => (amount * discount / 100);
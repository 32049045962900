import * as React from "react";
import { api } from "lib";
import { BuySettings } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  settings: BuySettings;
  load: (unit_id: number) => void;
}

const initialSettings = {
  settings: null,
  minifig_price_bands: [],
  misc_price_bands: [],
  bulk_price_bands: [],
};

export const useLoadBuySettings = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState<string>('');
  const [settings, setSettings] = React.useState<BuySettings>(initialSettings);

  const load = React.useCallback(async (unit_id: number) => {
    setStatus(api.loading);
    const resp = await api.get(`/api/units/${unit_id}/buys/settings`);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setSettings(resp.body);
    } else {
      setStatus(api.error);
      setError(resp.body);
    }
  }, []);

  return {
    status,
    error,
    settings,
    load,
  };
};


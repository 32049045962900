import * as React from "react";
import { api } from "lib";
import { HeartlandModel, UnitModel, UserModel } from "model";
import { StatusCodes } from "http-status-codes";

export type UnitInput = {
  franchise_id: number;
  name: string;
  address?: string;
  address2?: string;
  postal_code?: string;
  city?: string;
  country?: string;
  owner: UserModel;
  heartland?: HeartlandModel;
};

interface Props {
  status: string;
  error: string;
  unit: UnitModel | null;
  add: (unit: UnitInput) => void;
}

export const useAddUnit = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [unit, setUnit] = React.useState<UnitModel | null>(null);

  const add = React.useCallback(async (newUnit: UnitInput) => {
    setStatus(api.loading);
    const ownerId = newUnit.owner.id;
    const heartlandId = newUnit.heartland && newUnit.heartland.id > 0 ? newUnit.heartland.id : null;
    const payload = {
      unit: { ...newUnit, owner_id: ownerId, heartland_id: heartlandId }
    };
    const resp = await api.post(`/api/units/`, payload);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setUnit(resp.body);
    } else {
      setStatus(api.error);
      setUnit(null)
      setError(resp.body.error);
    }
  }, []);

  return {
    status,
    error,
    unit,
    add,
  };
};

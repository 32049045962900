import * as React from 'react';
import dayjs from 'dayjs';

import {Box, Button, Grid, Link, Paper, Typography} from '@mui/material';
import { DatePicker } from 'components';
import { useDailyBuysList } from 'api';
import { api, formatCurrency } from 'lib';
import { GridColDef, GridRenderCellParams, DataGrid } from '@mui/x-data-grid';
import { BuysCsv } from './csv';

const getRowId = (row: any) => {
  return `${row.year}-${row.month}-${row.day}`;
};

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>{params.row.month}-{params.row.day}-{params.row.year}</>
    )
  },
  {
    field: 'completed',
    type: 'number',
    headerName: 'Completed',
  },
  {
    field: 'cancelled',
    type: 'number',
    headerName: 'Cancelled',
  },
  {
    field: 'cash_count',
    type: 'number',
    headerName: 'Cash',
  },
  {
    field: 'cash_paid',
    type: 'number',
    headerName: 'Cash Paid',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>{formatCurrency(params.row.cash_paid)}</>
    )
  },
  {
    field: 'credit_count',
    type: 'number',
    headerName: 'Credit',
  },
  {
    field: 'credit_paid',
    type: 'number',
    headerName: 'Credit Paid',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>{formatCurrency(params.row.credit_paid)}</>
    )
  },
  {
    field: 'new_sets',
    type: 'number',
    headerName: 'New in Box',
  },
  {
    field: 'used_sets',
    type: 'number',
    headerName: 'Used Sets',
  },
  {
    field: 'minifigs',
    type: 'number',
    headerName: 'Minifigs',
  },
  {
    field: 'bulk',
    type: 'number',
    headerName: 'Bulk',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>{params.row.bulk}g</>
    )
  },
  {
    field: 'misc',
    type: 'number',
    headerName: 'Misc',
  },
];

export const BuysReport = () => {
  const { dailyBuysList, getDailyBuysList } = useDailyBuysList();
  const [startDate, setStartDate] = React.useState<string>(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = React.useState<string>(dayjs().endOf('month').format('YYYY-MM-DD'));

  const run = () => {
    getDailyBuysList(startDate, endDate);
  };

  const totals = React.useMemo(() => {
    const totals = {
      total: 0,
      totalPaid: 0,
      cash: 0,
      credit: 0,
      cashPaid: 0,
      creditPaid: 0,
      cancelled: 0,
    }
    dailyBuysList.buys.forEach((line) => {
      totals.total += +line.cash_count;
      totals.total += +line.credit_count;
      totals.totalPaid += +line.cash_paid;
      totals.totalPaid += +line.credit_paid;
      totals.cash += +line.cash_count;
      totals.credit += +line.credit_count;
      totals.cashPaid += +line.cash_paid;
      totals.creditPaid += +line.credit_paid;
      totals.cancelled += +line.cancelled;
    });
    return totals;
  }, [dailyBuysList]);

  return (
    <Box sx={{ mx: 2 }}>
      <Grid container spacing={2} sx={{ p: 2 }} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6"><strong>Daily Summary</strong></Typography>
        </Grid>
        <Grid item xs="auto">
          <DatePicker date={startDate} onChange={(date) => setStartDate(date)} />
        </Grid>
        <Grid item xs="auto">
          <DatePicker date={endDate} onChange={(date) => setEndDate(date)} />
        </Grid>
        <Grid item xs="auto">
          <Button variant="contained" onClick={run}>Run</Button>
        </Grid>
        <Grid item xs="auto">
          <BuysCsv list={dailyBuysList.buys} />
        </Grid>
        <Box sx={{ width: "100%" }} />
        <Grid item xs="auto">
          <Paper sx={{ p: 2 }}>
            <strong>Total Buys:</strong>&nbsp;&nbsp;
            {totals.total} /  {formatCurrency(totals.totalPaid)}
          </Paper>
        </Grid>
        <Grid item xs="auto">
          <Paper sx={{ p: 2 }}>
            <strong>Total Cash Buys:</strong>&nbsp;&nbsp;
            {totals.cash} / {formatCurrency(totals.cashPaid)}
          </Paper>
        </Grid>
        <Grid item xs="auto">
          <Paper sx={{ p: 2 }}>
            <strong>Total Credit Buys:</strong>&nbsp;&nbsp;
            {totals.credit} / {formatCurrency(totals.creditPaid)}
          </Paper>
        </Grid>
        <Grid item xs="auto">
          <Paper sx={{ p: 2 }}>
            <strong>Cancelled Buys:</strong>&nbsp;&nbsp;
            {totals.cancelled}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={dailyBuysList.buys}
            columns={columns}
            getRowId={getRowId}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 31 },
              },
            }}
            loading={dailyBuysList.status === api.loading}
            disableRowSelectionOnClick
            paginationMode="server"
          />
        </Grid>
      </Grid>
    </Box >
  );

};
import * as React from "react";
import { api } from "lib";
import { CustomerModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  customer: CustomerModel | null;
  update: (customer: CustomerModel) => void;
}

export const useUpdateCustomer = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [customer, setCustomer] = React.useState<CustomerModel | null>(null);

  const update = React.useCallback(async (updatedCustomer: CustomerModel) => {
    setStatus(api.loading);
    const payload = {
      customer: {
        first_name: updatedCustomer.first_name,
        last_name: updatedCustomer.last_name,
        phone: updatedCustomer.phone,
        email: updatedCustomer.email,
      },
    };
    const resp = await api.put(`/api/customers/${updatedCustomer.id}`, payload);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setCustomer(resp.body);
    } else {
      setStatus(api.error);
      setCustomer(null);
      setError(resp.body.error);
    }
  }, []);

  return {
    status,
    error,
    customer,
    update,
  };
};

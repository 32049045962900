import * as React from 'react';
import moment from 'moment';
import { Box, Grid } from '@mui/material';

import { BuyModel, NewSetBuyLineModel, UsedSetBuyLineModel } from 'model';
import { formatCurrency } from 'lib';

interface Props {
  buy: BuyModel;
}

interface UsedSetTagProps {
  buyId: number;
  updatedAt: string | undefined;
  line: UsedSetBuyLineModel;
}
const UsedSetTag: React.FC<UsedSetTagProps> = ({ buyId, updatedAt, line }: UsedSetTagProps) => {

  return (
    <Grid container spacing={1} sx={{ p: 4, my: 2, border: '1px solid #333' }} >
      <Grid item xs={12} sx={{ fontSize: '1.1em', fontWeight: 700 }}>
        {line.lego_set.set_number} {line.lego_set.name}
      </Grid>
      <Grid item xs={1.5}>
        Condition:
      </Grid>
      <Grid item xs={4.5}>
        {line.complete}
        {line.certifiable ? " (CERTIFIABLE)" : ""}
      </Grid>
      <Grid item xs={1.5}>
        Buy ID:
      </Grid>
      <Grid item xs={4.5}>
        PATRON-{buyId}
      </Grid>
      <Box sx={{ width: "100%" }} />
      <Grid item xs={1.5}>
        Cost:
      </Grid>
      <Grid item xs={4.5}>
        {formatCurrency(line.cost_final, true)}
      </Grid>
      <Grid item xs={1.5}>
        Date
      </Grid>
      <Grid item xs={4.5}>
        {moment(updatedAt).format("L")}
      </Grid>
      <Box sx={{ width: "100%" }} />
      <Grid item xs={1.5}>
        Value:
      </Grid>
      <Grid item xs={4.5}>
        {formatCurrency(line.value - line.value_discount, true)}
      </Grid>
      <Box sx={{ width: "100%" }} />
      <Grid item xs="auto">
        Notes:
      </Grid>
      <Grid item xs={10}>
        {line.notes}
      </Grid>
    </Grid>
  );
}

interface NewSetTagProps {
  buyId: number;
  updatedAt: string | undefined;
  line: NewSetBuyLineModel;
}
const NewSetTag: React.FC<NewSetTagProps> = ({ buyId, updatedAt, line }: NewSetTagProps) => {
  const condition = React.useMemo(() => {
    const cond: string[] = []
    if (line.opened) {
      cond.push("Open Box")
    }
    if (line.damaged) {
      cond.push("Damaged Box")
    }
    return cond.join(",")
  }, [line]);

  return (
    <Grid container spacing={1} sx={{ p: 4, my: 2, border: '1px solid #333' }} >
      <Grid item xs={12} sx={{ fontSize: '1.1em', fontWeight: 700 }}>
        {line.lego_set.set_number} {line.lego_set.name}
      </Grid>
      <Grid item xs={1.5}>
        Buy ID
      </Grid>
      <Grid item xs={4.5}>
        PATRON-{buyId}
      </Grid>
      <Grid item xs={1}>
        Date
      </Grid>
      <Grid item xs={5}>
        {moment(updatedAt).format("L")}
      </Grid>
      <Grid item xs={1}>
        Cost
      </Grid>
      <Grid item xs={2}>
        {formatCurrency(line.cost_final, true)}
      </Grid>
      <Grid item xs={1}>
        Value:
      </Grid>
      <Grid item xs={2}>
        {formatCurrency(line.value - line.value_discount, true)}
      </Grid>
      <Grid item xs="auto">
        Condition:
      </Grid>
      <Grid item xs={4}>
        {condition}
      </Grid>
      <Grid item xs="auto">
        Notes:
      </Grid>
      <Grid item xs={10}>
        {line.notes}
      </Grid>
    </Grid>
  );
}

export const ProcessingTags: React.FC<Props> = ({ buy }: Props) => {
  let ct = -1;
  return (
    <div style={{ margin: 40 }}>
      {buy.used_set_buy_lines.map((line) => {
        ct++
        return (
          <>
            {ct % 4 === 0 && <div key={line.id} style={{ pageBreakBefore: "always", marginTop: 80 }} />}
            <UsedSetTag buyId={buy.id} updatedAt={buy.updated_at} line={line} />
          </>
        );
      })}
      {buy.new_set_buy_lines.map((line) => {
        ct++
        return (
          <>
            {ct % 4 === 0 && <div key={line.id} style={{ pageBreakBefore: "always", marginTop: 80 }} />}
            <NewSetTag buyId={buy.id} updatedAt={buy.updated_at} line={line} />
          </>
        );
      })}
    </div>
  );
};

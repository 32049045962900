import { Box } from '@mui/material';
import * as React from 'react';

const style = {
  marginTop: 4,
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  '& img': {
    width: 300
  }
};

export const NotFound: React.FC = () => {
  return (
    <Box sx={style}>
      <h1> Sorry, the requested resource was not found!</h1>
      <img src="/notfound.png" alt="Not Found" />
    </Box>
  );
};
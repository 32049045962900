import * as React from 'react';
import { Box, Button, Checkbox, Grid } from '@mui/material';
import { SeriesMinifigModel } from 'model';

interface Props {
  minifig: SeriesMinifigModel;
  onClick?: (minifig: SeriesMinifigModel) => void;
  onCheck?: (minifig: SeriesMinifigModel, checked: boolean) => void;
}

export const CMFThumbnail: React.FC<Props> = ({ minifig, onClick, onCheck }: Props) => {

  const doClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(minifig);
    }
    e.stopPropagation();
  }

  const doCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onCheck) {
      onCheck(minifig, e.target.checked);
    }
  };

  return (
    <Grid container spacing={0} alignItems="center" sx={{ marginBottom: 1, border: '1px solid #eee' }}>
      <Grid item xs={12}>
        <Box sx={{ textAlign: 'center', margin: '0 auto' }}>
          <img alt={minifig.name} title={minifig.name} src={`${minifig.rebrickable_image_url}`} style={{ width: 'auto', height: 80 }} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ fontSize: 12, fontWeight: 700, textAlign: 'center', margin: 'auto' }}>
          {minifig.bricklink_id}<br />
          {minifig.name}<br />
        </Box>
      </Grid>
      {onClick &&
        <Grid item xs={12}>
          <Box sx={{ fontSize: 12, fontWeight: 700, textAlign: 'center', margin: 1 }}>
            <Checkbox onClick={(e) => e.stopPropagation()} onChange={doCheck} />
            < Button onClick={doClick} variant="contained" size="small">Select</Button>
          </Box>
        </Grid>
      }
    </Grid>
  );
};

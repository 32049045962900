import * as React from 'react';
import { TableRow, TableCell, IconButton, Box, Checkbox, Checkbox as MUICheckbox, Link, Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { SeriesMinifigBuyLineModel } from "model";
import { CMFCard, CardModal, ConditionSelector, CurrencyInput, EbayCMFLink, NotesInput, QuantityInput } from 'components';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { BuyContext, TBuyContext } from '../../buy_context';
import { useHeartlandItems } from 'api';

interface Props {
  row: SeriesMinifigBuyLineModel;
}

export const CMFBuyEntryRow: React.FC<Props> = ({ row }: Props) => {
  const { deleteCMF, updateCMF } = React.useContext(BuyContext) as TBuyContext;
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { getItem, items, status } = useHeartlandItems();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showCard, setShowCard] = React.useState<boolean>(false);
  const handleCloseCard = () => setShowCard(false);
  const [checked, setChecked] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const update = React.useCallback((prop: string, value: string | number | boolean) => {
    updateCMF({ ...row, [prop]: value });
    setAnchorEl(null);
  }, [updateCMF, row]);

  const remove = React.useCallback((id: number) => deleteCMF(id), [deleteCMF]);

  const adjValue = React.useMemo(() => row.value - row.value_discount, [row.value, row.value_discount]);

  React.useEffect(() => {
    if (row.series_minifig && currentHeartland) {
      getItem(currentHeartland.id, row.series_minifig);
    }
  }, [row.series_minifig, currentHeartland, getItem]);

  const getHLPrice = React.useMemo(() => {
    if (status === api.success && items) {
      const item = items.find((i) => i.custom.department === "Minifigs");
      if (item) {
        return Math.round(item.price);
      }
    }
    return 0;
  }, [items, status]);

  const getBLPrice = React.useMemo(() => {
    return Math.round(row.series_minifig.new_bricklink_prices.avg)
  }, [row]);

  return (
    <TableRow
      key={row.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <img src={row.series_minifig.rebrickable_image_url} alt={row.series_minifig.name} style={{ display: 'block', maxWidth: 64, maxHeight: 64 }} />
      </TableCell>
      <TableCell>
        <Box sx={{ mb: 2 }}>
          <Link onClick={() => setShowCard(true)}>{row.series_minifig.bricklink_id} - {row.series_minifig.name}</Link>
          <CardModal open={showCard} onClose={handleCloseCard}>
            <CMFCard minifig={row.series_minifig} />
          </CardModal>
        </Box>
        <NotesInput
          value={row.notes}
          onChange={(value: string) => update("notes", value)}
        />
      </TableCell>
      <TableCell>
        <EbayCMFLink item={row.series_minifig} /><br />
        <Link target="_bl" href={`https://www.bricklink.com/v2/catalog/catalogitem.page?S=${row.series_minifig.bricklink_id}`}>Bricklink</Link>
      </TableCell>
      <TableCell>
        <ConditionSelector value={row.condition} onSelect={(value) => update("condition", value)} />
      </TableCell>
      <TableCell align="center"><Checkbox checked={row.overstock} onChange={(e) => update("overstock", e.target.checked)} /></TableCell>
      <TableCell align="center">
        <QuantityInput quantity={row.quantity} onChange={(value) => update("quantity", value)} />
      </TableCell>
      <TableCell>
        <CurrencyInput value={row.value} label={''} onChange={(e) => update("value", +e.target.value)} />
        <IconButton
          id="price-button"
          size="small"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="price-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'price-button',
          }}
        >
          <MenuItem onClick={() => update("value", getHLPrice)}>Use Store Price</MenuItem>
          <MenuItem onClick={() => update("value", getBLPrice)}>Use Bricklink Price</MenuItem>
        </Menu>
      </TableCell>
      <TableCell align="right">{formatCurrency(adjValue, false)}</TableCell>
      <TableCell align="right">{formatCurrency(row.quantity * adjValue, false)}</TableCell>
      <TableCell>
        <IconButton aria-label="delete" onClick={() => remove(row.key)}> <DeleteIcon /></IconButton>
        <MUICheckbox onChange={(e) => setChecked(e.target.checked)} />
      </TableCell>
    </TableRow >
  );
};
import * as React from 'react';
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { MinifigModel } from 'model';
import { useHeartlandItems } from 'api/lego_set/heartland_items';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import moment from 'moment';

interface Props {
  minifig: MinifigModel | null;
}

export const MinifigCard: React.FC<Props> = ({ minifig }: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const formatPrice = (val: any): string => val ? '$' + Number(val).toFixed(2) : 'N/A';
  const { getItem, items, status } = useHeartlandItems();

  React.useEffect(() => {
    if (minifig && currentHeartland) {
      getItem(currentHeartland.id, minifig);
    }
  }, [minifig, currentHeartland, getItem]);

  if (!minifig) {
    return (<></>);
  }
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">{minifig.name} | {minifig.release_year} | {minifig.num_parts} parts</Typography>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ mb: 1, textAlign: 'center', mx: 'auto' }}>
            <img src={minifig.rebrickable_image_url} alt={minifig.name} style={{ maxWidth: 320, maxHeight: 320 }} />
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={0.25}>
                <Grid item xs={4}>
                  <Typography variant="body2">Bricklink:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    <Link target="_bl" href={`https://www.bricklink.com/v2/catalog/catalogitem.page?M=${minifig.bricklink_id}`}>{minifig.bricklink_id}</Link>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">Rebrickable:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    <Link target="_rb" href={`https://rebrickable.com/minifigs/${minifig.rebrickable_id}`}>{minifig.rebrickable_id}</Link>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">BrickOwl:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    <Link target="_bo" href={`https://brickowl.com/catalog/${minifig.brickowl_id}`}>{minifig.brickowl_id}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1} sx={{ fontSize: '0.85em' }}>
                <Grid item xs={3}>Bricklink</Grid>
                <Grid item xs={3}><strong>Used</strong></Grid>
                <Grid item xs={3}><strong>New</strong></Grid>
                <Box sx={{ width: "100%" }}></Box>

                <Grid item xs={3}>avg:</Grid>
                <Grid item xs={3}>{formatPrice(minifig.used_bricklink_prices.avg)}</Grid>
                <Grid item xs={3}>{formatPrice(minifig.new_bricklink_prices.avg)}</Grid>
                <Box sx={{ width: "100%" }}></Box>

                <Grid item xs={3}>min:</Grid>
                <Grid item xs={3}>{formatPrice(minifig.used_bricklink_prices.min)}</Grid>
                <Grid item xs={3}>{formatPrice(minifig.new_bricklink_prices.min)}</Grid>
                <Box sx={{ width: "100%" }}></Box>

                <Grid item xs={3}>max:</Grid>
                <Grid item xs={3}>{formatPrice(minifig.used_bricklink_prices.max)}</Grid>
                <Grid item xs={3}>{formatPrice(minifig.new_bricklink_prices.max)}</Grid>
                <Box sx={{ width: "100%" }}></Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}><Typography variant="body1" sx={{ fontWeight: 'bold' }}>My Store</Typography></Grid>
                {status === api.loading &&
                  <Grid item xs={12}><CircularProgress /> Loading Data</Grid>
                }
                {status === api.success && items.length > 0 &&
                  <>
                    <Grid item xs={2}>Item #</Grid>
                    <Grid item xs={2}>Department</Grid>
                    <Grid item xs={2}>Category</Grid>
                    <Grid item xs={4}>Description</Grid>
                    <Grid item xs={2}>Current</Grid>
                    {items.map((item) => (<>
                      <Grid item xs={2}>{item.public_id}</Grid>
                      <Grid item xs={2}>{item.custom.department}</Grid>
                      <Grid item xs={2}>{item.custom.category}</Grid>
                      <Grid item xs={4}> {item.description}</Grid>
                      <Grid item xs={2}>{formatCurrency(item.price)}</Grid>
                    </>))}
                  </>
                }
                {status === api.success && items.length === 0 &&
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ p: 2 }}>
                      Integrate store pricing by adding bricklink_id if you have this minifig
                      itemized in your Heartland Inventory
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}><Typography varian-="body1" sx={{ fontWeight: 700 }}>Recent Buys</Typography></Grid>
                <Grid item xs={3}>Date</Grid>
                <Grid item xs={2}>Type</Grid>
                <Grid item xs={3}>Condition</Grid>
                <Grid item xs={2}>Value</Grid>
                <Grid item xs={2}>Cost</Grid>
                {minifig.recent_buys.map((line) => (
                  <>
                    <Grid item xs={3}>{moment(line.created_at).format("L")}</Grid>
                    <Grid item xs={2}>{line.buy_type}</Grid>
                    <Grid item xs={3}>{line.condition}</Grid>
                    <Grid item xs={2}>{formatCurrency(line.value)}</Grid>
                    <Grid item xs={2}>{formatCurrency(line.cost)}</Grid>
                  </>
                ))}
              </Grid>
            </Grid>
          </Grid >
        </Grid>
      </Grid>
    </Box >
  );
};

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RestorePageIcon from '@mui/icons-material/RestorePage';

import { useUpdateRewardsSignupForm } from 'api';
import { InfoBar } from 'components';
import { api } from 'lib';
import { RewardsSignupFormModel, InfoBarModel, HeartlandCustomFieldModel } from 'model';
import { RewardsSignupForm } from 'components/rewards_signup_form';

const defaultRewardsSignupForm = {
  id: 0,
  heartland_id: 0,
  short_code: '',
  header: '',
  message: '',
  confirmation_message: '',
  email_marketing: false,
  emailmarketing_customfield: '',
  birth_month: false,
  birthmonth_customfield: '',
  source: false,
  source_customfield: '',
  default_customfield_values: {},
};

interface FormProps {
  rewardsSignupForm?: RewardsSignupFormModel;
  customFields?: HeartlandCustomFieldModel[];
}

export const RewardsFormTab: React.FC<FormProps> = (props: FormProps) => {
  const { state } = useLocation();
  const { status, error, rewardsSignupForm: updatedRewardsSignupForm, update } = useUpdateRewardsSignupForm();
  const [rewardsSignupForm, setRewardsSignupForm] = React.useState<RewardsSignupFormModel>(props.rewardsSignupForm ? props.rewardsSignupForm : defaultRewardsSignupForm);
  const [formValid, setFormValid] = React.useState<boolean>(true);
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);

  const doUpdate = () => { update(rewardsSignupForm) };
  const reset = () => setRewardsSignupForm(props.rewardsSignupForm ? props.rewardsSignupForm : defaultRewardsSignupForm);
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (state && state.message) {
      setInfoBar({ status: state.type, message: state.message });
    } else if (status === api.success && updatedRewardsSignupForm) {
      setRewardsSignupForm(updatedRewardsSignupForm);
      setInfoBar({ status: "success", message: 'successfully saved.' });
    } else if (status === api.error) {
      setInfoBar({ status: "error", message: error });
    } else if (!formValid) {
      setInfoBar({ status: "warning", message: "Required custom fields must have a default value!" });
    } else {
      setInfoBar(null);
    }
  }, [state, status, error, setInfoBar, setRewardsSignupForm, updatedRewardsSignupForm, formValid]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {infoBar &&
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        }
      </Grid>
      <Grid item xs={12} md={9}>
        <RewardsSignupForm
          rewardsSignupForm={rewardsSignupForm}
          customFields={props.customFields}
          isAdmin={true}
          onUpdate={(data, valid) => {
            setRewardsSignupForm(data);
            setFormValid(valid);
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Save Edits"
              startIcon={<SaveIcon />}
              onClick={doUpdate}
              fullWidth
              disabled={!formValid}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Reset"
              startIcon={<RestorePageIcon />}
              onClick={reset}
              fullWidth
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Cancel"
              startIcon={<CancelIcon />}
              href="/heartlands"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import * as React from "react";
import { api } from "lib";
import { BuySettings } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  msg: string;
  settings: BuySettings | null;
}

const defaultState: State = {
  status: api.idle,
  msg: '',
  settings: null,
};

interface Props {
  saveBuySettings: State &
  {
    save: (setting: BuySettings) => void;
    reset: (unitId: number, section: string) => void;
  }
}

export const useSaveBuySettings = (): Props => {
  const [state, setState] = React.useState(defaultState);

  const reset = React.useCallback(async (unitId: number, section: string) => {
    setState(prevState => ({ ...prevState, status: api.loading }));
    const resp = await api.post(`/api/units/${unitId}/buys/settings/reset_${section}`, {});
    if (resp.status === StatusCodes.OK) {
      setState({ settings: resp.body, status: api.success, msg: 'Settings reset' });
    } else {
      setState({ status: api.error, settings: null, msg: '' });
    }
  }, [setState]);

  const save = React.useCallback(async (settings: BuySettings) => {
    if (settings.settings) {
      setState(prevState => ({ ...prevState, status: api.loading }));
      const body = settings;
      const resp = await api.put(`/api/buys/settings/${settings.settings.id}`, body);
      if (resp.status === StatusCodes.OK) {
        setState({ settings: resp.body, status: api.success, msg: 'Settings saved' });
      } else {
        setState({ status: api.error, settings: null, msg: resp.body });
      }
    }
  }, [setState]);

  return { saveBuySettings: { ...state, save, reset, } };
};


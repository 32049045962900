import * as React from 'react';
import { Grid, Link } from '@mui/material';

import { LegoSetModel } from 'model';

interface Props {
  legoSet: LegoSetModel;
}

export const LegoSetExternalLinks: React.FC<Props> = ({ legoSet }: Props) => {
  return (
    <Grid container spacing={2} sx={{ fontSize: '0.90rem', mt: 1 }}>
      <Grid item xs="auto">
        LEGO: <Link href={`https://www.lego.com/en-us/search?q=${legoSet.set_number}`} target="_lego">{legoSet.set_number}</Link>
      </Grid>
      <Grid item xs="auto">
        Bricklink: <Link href={`https://www.bricklink.com/v2/catalog/catalogitem.page?S=${legoSet.bricklink_id}`} target="_bl">{legoSet.bricklink_id}</Link>
      </Grid>
      <Grid item xs="auto">
        Rebrickable: <Link href={`https://rebrickable.com/legoSets/${legoSet.rebrickable_id}`} target="_rb">{legoSet.rebrickable_id}</Link>
      </Grid>
      <Grid item xs="auto">
        BrickOwl: <Link href={`https://brickowl.com/catalog/${legoSet.brickowl_id}`} target="_bo">{legoSet.brickowl_id}</Link>
      </Grid>
      <Grid item xs="auto">
        BrickSet: <Link target="_bs" href={`https://brickset.com/sets/${legoSet.brickset_id}`}>{legoSet.brickset_id}</Link><br />
      </Grid>
    </Grid>
  );
};
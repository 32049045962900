import * as React from 'react';
import { Grid, Box, Button } from "@mui/material";
import { LegoSetModel } from 'model';

interface Props {
  legoSet: LegoSetModel;
  onClick?: (legoSet: LegoSetModel) => void;
}

export const SetThumbnail: React.FC<Props> = ({ legoSet, onClick }: Props) => {
  const doClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(legoSet);
    }
    e.stopPropagation();
  }

  return (
    <Grid container direction="row" sx={{ height: '100%' }}>
      <Grid item xs={12}>
        <Box sx={{ textAlign: 'center', margin: 'auto', paddingBottom: 2, fontWeight: 700 }}>
          {legoSet.set_number} - {legoSet.name}
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ margin: '0 auto', textAlign: 'center' }}>
        <img
          alt={legoSet.name}
          title={legoSet.name}
          src={legoSet.rebrickable_image_url}
          style={{ width: 'auto', height: 'auto', maxWidth: 120, maxHeight: 120 }}
        />
      </Grid>
      {onClick &&
        <Grid item xs={12} sx={{ margin: '0 auto', textAlign: 'center' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <span style={{ display: 'inline-block', alignSelf: 'flex-end', textAlign: 'center', margin: '0 auto 8px' }}>
              <Button onClick={doClick} variant="contained" size="small">Select</Button>
            </span>
          </div>
        </Grid>
      }
    </Grid>
  );
};
import * as React from "react";

import { useParams, useSearchParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { useGetBuy } from "api";
import { ErrorBar } from "components";
import { api } from "lib";

import { BuyInput } from "./buy_input";
import { BuySheet } from "./buy_sheet";
import { BuyForm } from "./buy_form";

export const ShowBuy = () => {
  const [searchParams] = useSearchParams();
  const { get, buy, status, error } = useGetBuy();
  const { buy_id } = useParams();

  React.useEffect(() => {
    if (buy_id) {
      get(parseInt(buy_id));
    }
  }, [buy_id, get]);

  if (status === api.success && buy) {
    if (buy.status === "completed") {
      if (searchParams.get("view") === "form") {
        return (<BuyForm buy={buy} />);
      }
      return (<BuySheet buy={buy} />);
    } else if (buy.status === "cancelled") {
      return (<BuySheet buy={buy} />);
    } else if (buy.status === "pending") {
      return (<BuyForm buy={buy} />);
    } else {
      return (<BuyInput buy={buy} />);
    }
  } else if (status === api.loading) {
    return (<LinearProgress />);
  } else if (status === api.error) {
    return (<ErrorBar error={error} />);
  } else {
    return (<></>);
  }
};
import * as React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { MinifigModel } from 'model';

interface Props {
  minifig: MinifigModel;
  quantity?: number;
  onClick?: (minifig: MinifigModel) => void;
}

export const MinifigThumbnail: React.FC<Props> = ({ minifig, quantity, onClick }: Props) => {

  const doClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(minifig);
    }
    e.stopPropagation();
  }

  return (
    <Grid container spacing={0} alignItems="center" sx={{ marginBottom: 1, border: '1px solid #eee' }}>
      <Grid item xs={12}>
        <Box sx={{ textAlign: 'center', margin: '0 auto' }}>
          <img alt={minifig.name} title={minifig.name} src={`${minifig.rebrickable_image_url}`} style={{ width: 'auto', height: 'auto', maxWidth: 80, maxHeight: 80 }} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ fontSize: 12, fontWeight: 700, textAlign: 'center', mx: 'auto', my: 1 }}>
          {minifig.bricklink_id} {quantity && `x${quantity}`}
        </Box>
      </Grid>
      {onClick &&
        <Grid item xs={12}>
          <Box sx={{ fontSize: 12, fontWeight: 700, textAlign: 'center', mx: 'auto', my: 1 }}>
            <Button onClick={doClick} variant="contained" size="small">Select</Button>
          </Box>
        </Grid>
      }
    </Grid>
  );
};

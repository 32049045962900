import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, LinearProgress, Link, Tab, Tabs } from '@mui/material';

import { api } from 'lib';
import { useGetHeartland } from 'api';
import { NotFound, RewardsFormTab } from 'components';
import useStyles from './styles';
import { CustomFieldsList } from './components/custom_fields';
import { HeartlandSettingsTab } from './components/heartland_settings_tab';

function a11yProps(index: number) {
  return {
    id: `heartland-tab-${index}`,
    'aria-controls': `heartland-tabpanel-${index}`,
  };
}

export const HeartlandShow: React.FC = () => {
  const { getHeartland } = useGetHeartland();
  const { heartland_id } = useParams();
  const classes = useStyles();

  const [tab, setTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const heartland = React.useMemo(() => getHeartland.heartland, [getHeartland]);

  const customFields = React.useMemo(() => {
    if (heartland && heartland.customer_custom_fields && heartland.item_custom_fields) {
      return heartland.customer_custom_fields?.concat(heartland.item_custom_fields);
    }
    return [];
  }, [heartland]);

  React.useEffect(() => {
    if (heartland_id) {
      getHeartland.byId(parseInt(heartland_id));
    }
  }, [heartland_id, getHeartland.byId]);

  if (getHeartland.status === api.loading) {
    return (<div style={{ margin: '32px 0', width: 800 }}><LinearProgress /></div>);
  } else if (getHeartland.status === api.success && heartland) {
    return (
      <div className={classes.show}>
        <h2><Link href="/heartlands">Heartlands</Link> :: {heartland.domain} </h2>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
          <Tabs value={tab} onChange={handleChange} aria-label="Heartland Tabs">
            <Tab label="Settings" {...a11yProps(0)} />
            <Tab label="Rewards Signup Form" {...a11yProps(1)} />
            <Tab label="Custom Fields" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <Box
          role="tabpanel"
          hidden={tab !== 0}
          id="heartland-tabpanel-0"
          aria-labelledby="heartland-tab-panel-0"
        >
          <HeartlandSettingsTab heartland={heartland} />
        </Box>
        <Box
          role="tabpanel"
          hidden={tab !== 1}
          id="heartland-tabpanel-1"
          aria-labelledby="heartland-tab-panel-1"
        >
          <RewardsFormTab rewardsSignupForm={heartland.rewards_signup_form} customFields={heartland.customer_custom_fields || []} />
        </Box>
        <Box
          role="tabpanel"
          hidden={tab !== 2}
          id="heartland-tabpanel-1"
          aria-labelledby="heartland-tab-panel-1"
        >
          <CustomFieldsList heartlandId={heartland.id} fields={customFields || []} />
        </Box>
      </div>
    );
  } else if (getHeartland.status === api.error) {
    return (<NotFound />);
  }
  return (<></>);
};

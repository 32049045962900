import * as React from 'react';
import { Alert, AlertColor, Grid, Button, Snackbar } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';


interface Props {
  message: any;
  status: AlertColor;
  onClose?: () => void;
}

export const InfoBar: React.FC<Props> = ({ message, status, onClose }: Props) => {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (!open && onClose) {
      onClose();
    }
  }, [open]);

  const renderMessage = () => {
    if (Array.isArray(message)) {
      return (
        <ul>
          {
            message.map((m, i) =>
              <li key={i}>{m}</li>
            )
          }
        </ul>
      );
    } else {
      return <span style={{ paddingRight: 16 }}>{message}</span>;
    }
  };

  return (
    <Snackbar
      sx={{ mt: 8 }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={4000}
      open={open}
      onClose={onClose}
    >
      <Alert severity={status} variant="filled"
        sx={{ width: '100%' }}>
        {renderMessage()}
      </Alert>
    </Snackbar>
  );
}
import * as React from "react";
import { SessionContext, TSessionContext, api } from "lib";
import { CustomerImportModel } from "model";

interface Props {
  status: string;
  error: string;
  customers: CustomerImportModel[];
  page: number;
  total: number;
  search: (query: string) => void;
}

interface State {
  status: string;
  error: string;
  customers: CustomerImportModel[];
  page: number;
  total: number;
}

const initialState: State = {
  status: api.idle,
  error: '',
  customers: [],
  page: 0,
  total: 0,
};

export const useImportSearch = (): Props => {
  const [state, setState] = React.useState<State>(initialState);
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;

  const search = React.useCallback(async (query: string) => {
    if (!currentHeartland) {
      setState({ status: api.error, customers: [], error: "Can't find associatd Heartland", page: 0, total: 0 });
      return;
    }
    setState({ status: api.loading, customers: [], error: '', page: 0, total: 0 });
    const resp = await api.get(`/api/heartlands/${currentHeartland.id}/customers/search`, { query: query });
    if (resp.status) {
      setState({ status: api.success, customers: resp.body.results, error: '', page: resp.body.page, total: resp.body.total });
    } else {
      setState({ status: api.error, customers: [], error: resp.body, page: 0, total: 0 });
    }
  }, [currentHeartland]);

  return {
    ...state,
    search,
  };
};

import React, { useContext } from 'react';
import { SessionContext, TSessionContext } from 'lib/session';
import { InfoBar } from '../info_bar';

export const FlashMessage: React.FC = () => {
    const { flashMessage, setFlashMessage } = useContext(SessionContext) as TSessionContext;

    if (!flashMessage) return null;

    return (
        <InfoBar
            message={flashMessage.message}
            status={flashMessage.status}
            onClose={() => setFlashMessage(null)}
        />
    );
};
import * as React from 'react';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { LegoSetModel } from 'model';
import { SetSearch } from 'components';
import { useLegoSetsMultiSearch } from 'api';
import { api } from 'lib';

interface Props {
  onAdd: (newSet: LegoSetModel | null) => void;
  onCancel: () => void;
}

export const NewSetSelector: React.FC<Props> = ({ onAdd, onCancel }: Props) => {
  const [search, setSearch] = React.useState<string>("");
  const { multisearch, status, sets } = useLegoSetsMultiSearch();
  const searchRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    searchRef.current?.focus();
  }, []);

  const updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const selectNewSet = (newSet: LegoSetModel) => {
    setSearch("");
    onAdd(newSet);
  };

  const doMultiSearch = () => {
    multisearch(search);
  };

  React.useEffect(() => {
    if (status === api.success) {
      setSearch("");
      sets.forEach((set) => {
        onAdd(set);
      });
    }
  }, [status, sets, onAdd]);

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12} md="auto">
        <h4>Add New:</h4>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField inputRef={searchRef} label="Search" variant="outlined" size="small" fullWidth onChange={updateSearch} value={search} />
      </Grid>
      <Grid item xs={1} md={1}>
        <Button onClick={doMultiSearch} >Multi-Add</Button>
      </Grid>
      <Grid item xs={1} md={1}>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      {search &&
        <Grid item xs={12}>
          <SetSearch query={search} onClick={selectNewSet} maxResults={12} />
        </Grid>
      }
    </Grid>
  );
};
import * as React from 'react';
import { Box } from '@mui/material';

import { LegoSetModel } from 'model';

interface Props {
  legoSet: LegoSetModel;
}

export const LegoSetRebrickableImage: React.FC<Props> = ({ legoSet }: Props) => {
  return (
    <Box sx={{ width: '100%', height: 300, textAlign: 'center', mx: 'auto' }}>
      <span style={{ display: 'inline-block', height: '100%', verticalAlign: 'middle' }}></span>
      <img src={legoSet.rebrickable_image_url} alt={legoSet.name} style={{ maxWidth: 370, maxHeight: 370, verticalAlign: 'middle' }} />
    </Box>
  );
};
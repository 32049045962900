import * as React from 'react';
import { TableRow, TableCell, Box, IconButton, Link, Menu, MenuItem, Checkbox as MUICheckbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { NewSetBuyLineModel } from "model";
import { CardModal, Checkbox, CurrencyInput, DesirabilitySelector, EbaySetLink, LegoSetCard, NotesInput, QuantityInput } from 'components';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { BuyContext, TBuyContext } from '../../buy_context';
import { useHeartlandItems } from 'api/lego_set/heartland_items';

interface Props {
  row: NewSetBuyLineModel;
}

export const NewSetBuyEntryRow: React.FC<Props> = ({ row }: Props) => {
  const { deleteNewSet, updateNewSet } = React.useContext(BuyContext) as TBuyContext;
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { getItem, items, status } = useHeartlandItems();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showCard, setShowCard] = React.useState<boolean>(false);
  const handleCloseCard = () => setShowCard(false);
  const [checked, setChecked] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const update = (prop: string, value: string | number | boolean) => {
    updateNewSet({ ...row, [prop]: value });
    setAnchorEl(null);
  };

  const remove = (id: number) => deleteNewSet(id);

  const adjValue = React.useMemo(() => row.value - row.value_discount, [row.value, row.value_discount]);

  React.useEffect(() => {
    if (row.lego_set && currentHeartland) {
      getItem(currentHeartland.id, row.lego_set);
    }
  }, [row.lego_set, currentHeartland, getItem]);

  const getHLPrice = React.useMemo(() => {
    if (status === api.success && items) {
      const item = items.find((i) => i.custom.department === "New Sets");
      if (item) {
        return Math.round(item.price);
      }
    }
    return 0;
  }, [items, status]);

  const getBLPrice = React.useMemo(() => {
    return Math.round(row.lego_set.new_bricklink_prices.avg)
  }, [row]);

  return (
    <TableRow
      key={row.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Box sx={{ mb: 1, fontWeight: 600 }}>
          <Link onClick={() => setShowCard(true)}>{row.lego_set.set_number} &mdash; {row.lego_set.name} ({row.lego_set.num_minifigs} minifigs)</Link>
          <CardModal
            open={showCard}
            onClose={handleCloseCard}
          >
            <LegoSetCard legoSet={row.lego_set} />
          </CardModal>
        </Box>
        <NotesInput
          value={row.notes}
          onChange={(value: string) => update("notes", value)}
        />
      </TableCell>
      <TableCell>
        <EbaySetLink item={row.lego_set} condition="new" /><br />
        <Link target="_bs" href={`https://brickset.com/sets/${row.lego_set.brickset_id}`}>Brickset</Link><br />
        <Link target="_bl" href={`https://www.bricklink.com/v2/catalog/catalogitem.page?S=${row.lego_set.bricklink_id}`}>Bricklink</Link><br />
      </TableCell>
      <TableCell>
        <Checkbox checked={row.opened} label="Open Box" onChange={(e) => update("opened", e.target.checked)} /><br />
        <Checkbox checked={row.damaged} label="Damaged" onChange={(e) => update("damaged", e.target.checked)} /><br />
        <Checkbox checked={row.overstock} label="Overstock" onChange={(e) => update("overstock", e.target.checked)} /><br />
      </TableCell>
      <TableCell>
        <DesirabilitySelector value={row.desirability} onSelect={(value) => update("desirability", value)} />
      </TableCell>
      <TableCell align="center">
        <QuantityInput quantity={row.quantity} onChange={(value) => update("quantity", value)} />
      </TableCell>
      <TableCell align="center">
        <CurrencyInput value={row.value} label={''} onChange={(e) => update("value", +e.target.value)} />
        <IconButton
          id="price-button"
          size="small"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="price-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'price-button',
          }}
        >
          <MenuItem onClick={() => update("value", getHLPrice)}>Use Store Price</MenuItem>
          <MenuItem onClick={() => update("value", getBLPrice)}>Use Bricklink Price</MenuItem>
        </Menu>
      </TableCell>
      <TableCell align="right">{formatCurrency(adjValue, false)}</TableCell>
      <TableCell align="right">{formatCurrency(row.quantity * adjValue, false)}</TableCell>
      <TableCell>
        <IconButton aria-label="delete" onClick={() => remove(row.key)}> <DeleteIcon /></IconButton>
        <MUICheckbox onChange={(e) => setChecked(e.target.checked)} />
      </TableCell>
    </TableRow >
  );
};
import * as React from 'react';
import { debounce } from 'lodash';
import { LinearProgress, Grid, Button } from '@mui/material';

import { useMinifigsSearch } from 'api';
import { MinifigThumbnail, MinifigCard, CardModal } from 'components';
import { api } from 'lib';
import { MinifigModel } from 'model';

interface Props {
  query: string;
  onClick?: (minifig: MinifigModel) => void;
  maxResults?: number
}

export const MinifigSearch: React.FC<Props> = ({ query, onClick, maxResults }: Props) => {
  const { search, status, total, minifigs } = useMinifigsSearch();
  const [page, setPage] = React.useState({ index: 0, total: 0 });
  const [selected, setSelected] = React.useState<MinifigModel | null>(null);
  const handleOpen = (minifig: MinifigModel) => setSelected(minifig);
  const handleClose = () => setSelected(null);

  const doSearch = React.useMemo(() => debounce(search, 500), [search]);


  React.useEffect(() => {
    if (query.length >= 3) {
      doSearch(query, page.index, maxResults ? maxResults : 24);
    }
  }, [query, page, doSearch, maxResults]);

  React.useEffect(() => {
    setPage({ index: 0, total: Math.ceil(total / (maxResults ? maxResults : 24)) })
  }, [total, maxResults]);

  const nextPage = () => {
    const newIndex = page.index + 1;
    setPage({ index: newIndex > page.total - 1 ? page.total - 1 : newIndex, total: page.total });
  };
  const prevPage = () => {
    const newIndex = page.index - 1;
    setPage({ index: newIndex < 0 ? 0 : newIndex, total: page.total });
  };

  if (status === api.loading) {
    return (<div style={{ margin: '32px 0', width: 800 }}><LinearProgress /></div>);
  } else if (status === api.success) {
    return (
      <>
        <Grid container alignItems="center" spacing={1}>
          {minifigs && minifigs.map((minifig =>
            <Grid item xs={12} md={2} key={minifig.id}>
              <div onClick={() => handleOpen(minifig)}>
                <MinifigThumbnail minifig={minifig} onClick={onClick} />
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container alignItems="center" spacing={1} sx={{ marginTop: 1 }}>
          <Grid item xs={12} md={12} sx={{ textAlign: 'right', fontSize: '0.9em', marginBottom: 20 }}>
            <Button onClick={prevPage}><strong>&lt;</strong></Button>
            <Button onClick={nextPage}><strong>&gt;</strong></Button>
            page {page.index + 1} of {page.total} (Total {total})
          </Grid>
        </Grid >
        <CardModal
          open={selected !== null}
          onClose={handleClose}
        >
          <MinifigCard minifig={selected} />
        </CardModal>
      </>
    );
  } else {
    return (<></>);
  }
};
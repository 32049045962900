import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, Grid, LinearProgress, Link, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { SessionContext, TSessionContext, api } from 'lib';
import { useGetCustomer, useUpdateCustomer } from '../../api/customer';
import useStyles from './styles';
import { CustomerModel, InfoBarModel } from 'model';
import { InfoBar, NotFound } from 'components';
import { DataGrid } from '@mui/x-data-grid';
import { BuyColumns } from 'components/tables';
import { useBuysList } from 'api';

export const CustomerShow: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { customer_id } = useParams();
  const { customer, status, get } = useGetCustomer();
  const classes = useStyles();

  React.useEffect(() => {
    if (customer_id) {
      get(parseInt(customer_id));
    }
  }, [customer_id, get]);

  if (status === api.loading) {
    return (<div style={{ marginTop: 32, width: 800 }}><LinearProgress /></div>);
  } else if (status === api.error || !currentUser) {
    return (
      <div style={{ width: 800, margin: '0 auto', textAlign: 'center' }}>
        <NotFound />
        <h4><Link href="/customers">Return to Customers List</Link></h4>
      </div >);
  } else if (status === api.success && customer) {
    return (
      <div className={classes.show}>
        <CustomerForm customer={customer} />
      </div>
    );
  }
  return (<></>);
};

interface FormProps {
  customer: CustomerModel;
}

export const CustomerForm: React.FC<FormProps> = (props: FormProps) => {
  const [customer, setCustomer] = React.useState<CustomerModel>(props.customer);
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const { status, error, update } = useUpdateCustomer();
  const updateCustomer = (prop: string, value: string) => setCustomer({ ...customer, [prop]: value });
  const doUpdate = () => update(customer);
  const doReset = () => setCustomer(props.customer);
  const { buysList, listBuysByCustomer } = useBuysList();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  React.useEffect(() => {
    if (customer.id) {
      listBuysByCustomer(customer.id, paginationModel.page, paginationModel.pageSize);
    }
  }, [customer, paginationModel, listBuysByCustomer]);

  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const { state } = useLocation();
  const clearInfoBar = () => setInfoBar(null);


  React.useEffect(() => {
    if (state && state.message) {
      setInfoBar({ status: state.type, message: state.message });
    } else if (status === api.success) {
      setInfoBar({ status: "success", message: 'Customer Saved.' });
    } else if (status === api.error) {
      setInfoBar({ status: "error", message: error });
    } else {
      setInfoBar(null);
    }
  }, [state, status, error, setInfoBar]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <h2> <Link href="/customers" className="nav">Customers</Link> :: {customer.first_name} {customer.last_name}</h2>
      </Grid>
      <Grid item xs={12} md={12}>
        {infoBar &&
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        }
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="id"
              label="Patron ID"
              variant="outlined"
              fullWidth
              value={customer.id}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="external_id"
              label="Heartland ID"
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              value={customer.external_id}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="first_name"
              label="First Name"
              variant="outlined"
              fullWidth
              value={customer.first_name}
              onChange={(e) => updateCustomer('first_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="last_name"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={customer.last_name}
              onChange={(e) => updateCustomer('last_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              value={customer.email}
              onChange={(e) => updateCustomer('email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="phone"
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={customer.phone}
              onChange={(e) => updateCustomer('phone', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="store_credit_balance"
              label="Store Credit Balance"
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              value={customer.store_credit_balance}
            />
          </Grid>
          {isAdmin() &&
            <Grid item xs={12} md={12}>
              <h3>Associated Units</h3>
              <Grid container spacing={2}>
                {customer.units && customer.units.map((u) =>
                  <Grid item key={u.id} xs={6}><Link href={`/units/${u.id}`}>{u.name}</Link></Grid>
                )}
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Save"
              startIcon={<SaveIcon />}
              onClick={doUpdate}
              fullWidth>
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Link"
              startIcon={<RestorePageIcon />}
              onClick={doReset}
              fullWidth>
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Link"
              startIcon={<CancelIcon />}
              href="/customers"
              fullWidth >
              Cancel
            </Button>
          </Grid>
          {/* {!customer.heartland_id &&
                <Button variant="contained" aria-label="Link" startIcon={<LinkIcon />}>Link with Heartland</Button>
              }
              {(customer.heartland_id ) &&
                <>
                  <div className="info">
                    Heartland ID: <Link href={`https://${currentUser.store.heartland_domain}.retail.heartland.us/#customers/edit/${customer.heartland_id}`}>{customer.heartland_id}</Link>
                  </div>
                  <Button variant="contained" aria-label="sync" startIcon={<SyncIcon />}>Sync with Heartland</Button>
                </>
              } */}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <h3>Trade History</h3>
        {buysList.status === api.error &&
          <InfoBar status="error" message={buysList.error} />
        }
        <Box sx={{ width: '95%', pt: 2, fontSize: '0.9rem' }}>
          <DataGrid
            rows={buysList.buys}
            columns={BuyColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            loading={buysList.status === api.loading}
            rowCount={buysList.total}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableRowSelectionOnClick
            paginationMode="server"
          />
        </Box>
      </Grid>
    </Grid >
  );
};

import * as React from 'react';
import { FormLabel, RadioGroup, FormControlLabel, Radio, Grid, TextField } from '@mui/material';

import { CMFSearch, MinifigSearch, SetSearch } from 'components';

export const CatalogDashboard: React.FC = () => {
  const [search, setSearch] = React.useState("");
  const [searchType, setSearchType] = React.useState("minifigs");

  const updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchType((event.target as HTMLInputElement).value);
  };

  return (
    <div style={{ width: "80%" }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={1}>
          <FormLabel id="search-for-label">Search for: </FormLabel>
        </Grid>
        <Grid item xs={12} md={3}>
          <RadioGroup
            row
            aria-labelledby="search-for-label"
            defaultValue="Minifigures"
            name="search-for"
            value={searchType}
            onChange={handleChange}
          >
            <FormControlLabel value="minifigs" control={<Radio />} label="Minifigs" />
            <FormControlLabel value="cmf" control={<Radio />} label="CMF" />
            <FormControlLabel value="sets" control={<Radio />} label="Sets" />
          </RadioGroup>
        </Grid>
        <Grid item xs={8}>
          <TextField fullWidth onChange={updateSearch} />
        </Grid>
        <Grid item xs={12}>
          {searchType === 'cmf' && <CMFSearch query={search} />}
          {searchType === 'minifigs' && <MinifigSearch query={search} />}
          {searchType === 'sets' && <SetSearch query={search} />}
        </Grid>
      </Grid>
    </div>
  );
};
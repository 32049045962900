import * as React from "react";
import { api } from "lib";
import { HeartlandModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  heartland: HeartlandModel | null;
  update: (heartland: HeartlandModel) => void;
  updateApiKey: (id: number, apiKey: string) => void;
}

export const useUpdateHeartland = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [heartland, setHeartland] = React.useState<HeartlandModel | null>(null);

  const updateApiKey = React.useCallback(async (id: number, apiKey: string) => {
    setStatus(api.loading);
    const payload = {
      heartland: {
        api_key: apiKey,
      },
    };
    const resp = await api.put(`/api/heartlands/${id}`, payload);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      const updatedHeartland: HeartlandModel = resp.body.heartland;
      updatedHeartland.rewards_signup_form = resp.body.rewards_signup_form;
      setHeartland(updatedHeartland);
    } else {
      setStatus(api.error);
      setHeartland(null);
      setError(resp.body.error);
    }
  }, []);

  const update = React.useCallback(async (updatedHeartland: HeartlandModel) => {
    setStatus(api.loading);
    const payload = {
      heartland: {
        domain: updatedHeartland.domain,
      },
      // rewards_signup_form: updatedRewardsSignupForm
    };
    const resp = await api.put(`/api/heartlands/${updatedHeartland.id}`, payload);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      const updatedHeartland: HeartlandModel = resp.body.heartland;
      // updatedHeartland.rewards_signup_form = resp.body.rewards_signup_form;
      setHeartland(updatedHeartland);
    } else {
      setStatus(api.error);
      setHeartland(null);
      setError(resp.body.error);
    }
  }, []);

  return {
    status,
    error,
    heartland,
    update,
    updateApiKey,
  };
};

import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Link } from "@mui/material";
import React from "react";
import { formatCurrency } from "lib";
import { BuyModel, LegoSetModel, NewSetBuyLineModel } from "model";
import { CardModal, LegoSetCard } from "components";
import { cost } from "./helpers";

interface Props {
  buy: BuyModel;
}

export const NewSetSection: React.FC<Props> = ({ buy }: Props) => {
  const [selectedSet, setSelectedSet] = React.useState<LegoSetModel | null>(null);
  const handleCloseCard = () => setSelectedSet(null);
  let total = 0;

  const getRows: NewSetBuyLineModel[] = React.useMemo(() => {
    return buy.new_set_buy_lines.filter((row) => !row.deleted).sort((a, b) => a.lego_set.set_number > b.lego_set.set_number ? 1 : -1);
  }, [buy.new_set_buy_lines]);

  return (
    <TableContainer component={Paper}>
      <Box sx={{ p: 2, fontWeight: 700 }}>New In Box</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="NewSetetures">
        <TableHead>
          <TableRow>
            <TableCell width={"40%"}>Description</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="center">Damaged?</TableCell>
            <TableCell align="center">Open?</TableCell>
            <TableCell width={50} align="right">Qty</TableCell>
            <TableCell width={80} align="right">Cost</TableCell>
            <TableCell width={80} align="right">Value</TableCell>
            <TableCell width={80} align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => {
            const adjValue = row.value - row.value_discount;
            total += row.cost_final * row.quantity;
            return (
              <TableRow key={row.id}>
                <TableCell>
                  <Link onClick={() => setSelectedSet(row.lego_set)}>{row.lego_set.set_number} {row.lego_set.name}</Link>
                  <br />
                  {row.notes}
                </TableCell>
                <TableCell>{row.lego_set.category}</TableCell>
                <TableCell align="center">{row.damaged ? "yes" : "no"}</TableCell>
                <TableCell align="center">{row.opened ? "yes" : "no"}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{formatCurrency(row.cost_final)}</TableCell>
                <TableCell align="right">{formatCurrency(adjValue)}</TableCell>
                <TableCell align="right">{formatCurrency(row.quantity * adjValue)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align="right" colSpan={4}></TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>{buy.total_nib_qty}</TableCell>
            <TableCell align="right">{formatCurrency(total)}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>{formatCurrency(buy.total_nib_value)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardModal open={selectedSet !== null} onClose={handleCloseCard} >
        <LegoSetCard legoSet={selectedSet} />
      </CardModal>
    </TableContainer >
  );
};
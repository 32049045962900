import * as React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
import { HeartlandCustomFieldModel } from "model";
import { useSyncCustomFields } from '../../../api/heartland';

interface Props {
  heartlandId: number;
  fields?: HeartlandCustomFieldModel[];
}

const headerStyle = {
  fontWeight: 700
};


export const CustomFieldsList: React.FC<Props> = ({ heartlandId, fields }: Props) => {
  const { sync } = useSyncCustomFields();

  const validationOptions = (value: string): string => {
    const data = JSON.parse(value);
    return data ? data.join(", ") : "";
  };

  const doSync = () => {
    sync(heartlandId);
  };

  if (!fields) {
    return (<></>);
  }
  return (
    <div style={{ marginTop: 16 }}>
      <p>
        <em>Noted: Custom Fields can only be edited in Heartland Retail.</em>&nbsp;
        <Button variant="contained" startIcon={<SyncIcon />} onClick={doSync}>Sync</Button>
      </p>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={headerStyle}>Field</TableCell>
            <TableCell sx={headerStyle}>Name</TableCell>
            <TableCell sx={headerStyle}>Group</TableCell>
            <TableCell sx={headerStyle}>Required?</TableCell>
            <TableCell sx={headerStyle}>Type</TableCell>
            <TableCell sx={headerStyle}>Options</TableCell>
            <TableCell sx={headerStyle}>Last Synced</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((field) =>
            <TableRow key={field.key}>
              <TableCell>{field.key}</TableCell>
              <TableCell>{field.name}</TableCell>
              <TableCell>{field.group_id}</TableCell>
              <TableCell>{field.required ? 'yes' : 'no'}</TableCell>
              <TableCell>{field.validation_type}</TableCell>
              <TableCell>{validationOptions(field.validation_options)}</TableCell>
              <TableCell>{field.updated_at}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div >
  );
};

import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import React from "react";
import { BuyContext, TBuyContext } from "../../buy_context";
import { NewSetBuyEntryRow } from "./new_set_buy_entry_row";
import { formatCurrency } from "lib";
import { NewSetBuyLineModel } from "model";


export const NewSetSection: React.FC = () => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;

  const getRows: NewSetBuyLineModel[] = React.useMemo(() => {
    return buy.new_set_buy_lines.filter((row) => !row.deleted).sort((a, b) => a.lego_set.set_number > b.lego_set.set_number ? 1 : -1);
  }, [buy.new_set_buy_lines, buy.new_set_buy_lines.length]);

  return (
    <TableContainer component={Paper} sx={{ background: "#f9f0f0" }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>New In Box</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="New Sets">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="left">Pricing</TableCell>
            <TableCell align="left">Condition</TableCell>
            <TableCell>Desirability</TableCell>
            <TableCell width={72} align="center">Qty</TableCell>
            <TableCell width={150} align="center">Value</TableCell>
            <TableCell width={72} align="right">Adj Value</TableCell>
            <TableCell width={72} align="right">Total</TableCell>
            <TableCell width={120}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => (<NewSetBuyEntryRow key={row.id || row.key} row={row} />))}
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell align="right">Total</TableCell>
            <TableCell align="center">{buy.total_nib_qty}</TableCell>
            <TableCell align="right" colSpan={2}>Total</TableCell>
            <TableCell align="right">{formatCurrency(buy.total_nib_value)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
import * as React from "react";
import { Box } from "@mui/material";
import { LegoSetMyStoreInfo } from "components/lego_set_card/my_store_info";
import { LegoSetContext, TLegoSetContext } from "./context";
import { BuyHistory } from './buy_history';

export const MyStoreInfo: React.FC = () => {
  const { legoSet } = React.useContext(LegoSetContext) as TLegoSetContext;

  return (
    <Box>
      <Box sx={{ mb: 5, width: "80%", mx: "auto", textAlign: "left" }}>
        <LegoSetMyStoreInfo legoSet={legoSet} showImport={true} />
      </Box>
      <Box sx={{ mb: 4, width: "80%", mx: "auto", textAlign: "left" }}>
        <BuyHistory legoSet={legoSet} type="New" />
        <BuyHistory legoSet={legoSet} type="Used" />
      </Box>
    </Box>
  );
};
export function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { UnitModel } from 'model';
import { SessionContext, TSessionContext } from 'lib';

interface Props {
  onSelect?: (unit: UnitModel) => void;
  value?: UnitModel | null;
  size?: "small" | "medium";
  label?: boolean;
  variant?: "outlined" | "standard" | "filled" | undefined;
};

export const UnitSelector: React.FC<Props> = ({ onSelect, value = null, size = "medium", label = true, variant = "outlined" }: Props) => {
  const { units } = React.useContext(SessionContext) as TSessionContext;
  const [selectedUnit, setSelectedUnit] = React.useState<UnitModel | null>(null);

  const handleChange = (event: SelectChangeEvent) => {
    if (units) {
      const unitId: number = +event.target.value;
      const unit = units.find(s => s.id === unitId);
      if (unit) {
        setSelectedUnit(unit);
        if (onSelect) {
          onSelect(unit);
        }
      }
    }
  };

  React.useEffect(() => {
    if (value) {
      setSelectedUnit(value);
    }
  }, [value]);

  if (units) {
    if (units.length > 1) {
      return (
        <FormControl fullWidth variant={variant}>
          {label && <InputLabel id="unit-select-label">Location</InputLabel>}
          <Select
            labelId="unit-select-label"
            id="unit"
            value={`${value ? value.id : ""}`}
            onChange={handleChange}
            size={size}
            label={label ? label : null}
          >
            {units.map((unit: UnitModel) =>
              <MenuItem key={unit.id} value={`${unit.id}`}>{unit.name}</MenuItem>
            )}
          </Select>
        </FormControl >
      );
    } else if (units.length === 1) {
      return (<div>{units[0].name}</div>);
    } else {
      return (<div>No Locations Found!</div>);
    }
  } else {
    return (<div>Unable to load locations</div>);
  }
};

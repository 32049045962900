import {
  BuyModel,
  UsedSetBuyLineModel,
  NewSetBuyLineModel,
  MinifigBuyLineModel,
  SeriesMinifigBuyLineModel,
  BulkBuyLineModel,
  MiscBuyLineModel
} from "model";

export const addKeys = (buy: BuyModel): BuyModel => {
  let ct = 0;
  let totalUsedValue = 0;
  let totalUsedQuantity = 0;
  const margin = (buy.buy_type === "cash" ? buy.cash_paid : buy.credit_paid) / buy.total_retail;
  const usedSets = buy.used_set_buy_lines.map((line: UsedSetBuyLineModel) => {
    totalUsedValue += (line.value - line.value_discount) * line.quantity;
    totalUsedQuantity += line.quantity;
    return { ...line, default_value: line.value, key: ct++ }
  });
  ct = 0;
  let totalNewValue = 0;
  let totalNewQuantity = 0;
  const newSets = buy.new_set_buy_lines.map((line: NewSetBuyLineModel) => {
    totalNewValue += (line.value - line.value_discount) * line.quantity;
    totalNewQuantity += line.quantity;
    return { ...line, default_value: line.value, key: ct++ }
  });
  ct = 0;
  let totalMinifigValue = 0;
  let totalMinifigQuantity = 0;
  const minifigs = buy.minifig_buy_lines.map((line: MinifigBuyLineModel) => {
    totalMinifigValue += (line.value - line.value_discount) * line.quantity;
    totalMinifigQuantity += line.quantity;
    return { ...line, default_value: line.value, key: ct++ }
  });
  ct = 0;
  let totalCMFValue = 0;
  let totalCMFQuantity = 0;
  const seriesFigs = buy.series_minifig_buy_lines.map((line: SeriesMinifigBuyLineModel) => {
    totalCMFValue += (line.value - line.value_discount) * line.quantity;
    totalCMFQuantity += line.quantity;
    return { ...line, default_value: line.value, key: ct++ }
  });
  ct = 0;
  let totalBulkValue = 0;
  let totalBulkQuantity = 0;
  const bulk = buy.bulk_buy_lines.map((line: BulkBuyLineModel) => {
    totalBulkValue += (line.value - line.value_discount) * line.quantity;
    totalBulkQuantity += line.quantity;
    return { ...line, default_value: line.value, key: ct++ }
  });
  ct = 0;
  let totalMiscValue = 0;
  let totalMiscQuantity = 0;
  const misc = buy.misc_buy_lines.map((line: MiscBuyLineModel) => {
    totalMiscValue += (line.value - line.value_discount) * line.quantity;
    totalMiscQuantity += line.quantity;
    return { ...line, default_value: line.value, key: ct++ }
  });
  return {
    ...buy,
    total_used_value: totalUsedValue,
    total_used_qty: totalUsedQuantity,
    total_nib_value: totalNewValue,
    total_nib_qty: totalNewQuantity,
    total_minifig_value: totalMinifigValue,
    total_minifig_qty: totalMinifigQuantity,
    total_cmf_value: totalCMFValue,
    total_cmf_qty: totalCMFQuantity,
    total_bulk_value: totalBulkValue,
    total_bulk_qty: totalBulkQuantity,
    total_misc_value: totalMiscValue,
    total_misc_qty: totalMiscQuantity,
    used_set_buy_lines: usedSets,
    new_set_buy_lines: newSets,
    minifig_buy_lines: minifigs,
    series_minifig_buy_lines: seriesFigs,
    bulk_buy_lines: bulk,
    misc_buy_lines: misc,
  };
};
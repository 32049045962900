import * as React from 'react';
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useLoadBuySettings } from 'api';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { Checkbox, ConfirmationDialog, DefaultPriceSelector, DesirabilitySelector, InfoBar, PercentageInput } from 'components';
import { BuySettingModel, InfoBarModel } from 'model';
import { useSaveBuySettings } from 'api/buy/save_settings';

export const NibBuySettingsTab: React.FC = () => {
  const { currentUnit, currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { load, settings: s, status } = useLoadBuySettings();
  const { saveBuySettings } = useSaveBuySettings();
  const [settings, setSettings] = React.useState<BuySettingModel | undefined>();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [damagedPreview, setDamagedPreview] = React.useState<boolean>(false);
  const [overstockPreview, setOverstockPreview] = React.useState<boolean>(false);
  const [desirabilityPreview, setDesirabilityPreview] = React.useState<string>("good_seller");
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    }
  }, [currentUnit, currentUser, load]);

  React.useEffect(() => {
    if (status === api.success && s?.settings) {
      setSettings(s.settings);
    }
  }, [s, status]);

  React.useEffect(() => {
    if (saveBuySettings.status === api.success && saveBuySettings.settings?.settings) {
      setSettings(saveBuySettings.settings.settings);
    }
  }, [saveBuySettings]);

  const updateDefaultPrice = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: value };
      setSettings(newSettings);
    }
  };

  const updateSetting = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: +value };
      setSettings(newSettings);
    }
  };

  React.useEffect(() => {
    if (saveBuySettings.status === api.success) {
      setInfoBar({ status: "success", message: saveBuySettings.msg });
    } else if (saveBuySettings.status === api.error) {
      setInfoBar({ status: "error", message: saveBuySettings.msg });
    } else {
      setInfoBar(null);
    }
  }, [saveBuySettings.status, saveBuySettings.msg, setInfoBar]);

  const [showResetConfirm, setShowResetConfirm] = React.useState<boolean>(false);
  const resetSettings = () => setShowResetConfirm(true);
  const handleResetConfirm = (value: boolean) => {
    setShowResetConfirm(false);
    if (value && currentUnit) {
      saveBuySettings.reset(currentUnit.id, 'minifigs');
    }
  };

  const saveSettings = () => {
    if (settings && s) {
      saveBuySettings.save({ ...s, settings: settings });
    }
  };

  const value = React.useMemo((): number => {
    let value = 50;
    if (settings) {
      let discount = 0;
      if (openPreview) {
        discount += value * (1 - settings.nib_open_adj / 100);
      }
      if (damagedPreview) {
        discount += value * (1 - settings.nib_damaged_adj / 100);
      }
      value -= discount;
    }
    return value < 0 ? 0 : value;
  }, [settings, openPreview, damagedPreview]);

  const adjValue = React.useMemo((): number => {
    let adjValue = value;
    if (settings) {
      let discount = 0;
      if (overstockPreview) {
        discount = value * (1 - settings.nib_overstock_adj / 100);
      }
      if (desirabilityPreview === "hot_seller") {
        discount += value * (1 - settings.nib_hot_seller_adj / 100);
      }
      if (desirabilityPreview === "mediocre_seller") {
        discount += value * (1 - settings.nib_mediocre_adj / 100);
      }
      if (desirabilityPreview === "dust_collector") {
        discount += value * (1 - settings.nib_dust_collector_adj / 100);
      }
      if (desirabilityPreview === "not_interested") {
        discount += value * (1 - settings.nib_not_interested_adj / 100);
      }
      adjValue -= discount;
    }
    return adjValue < 0 ? 0 : adjValue;
  }, [settings, value, overstockPreview, desirabilityPreview]);

  const credit = React.useMemo((): { min: number, max: number } => {
    let min = adjValue;
    let max = adjValue;
    if (settings) {
      max = max * settings.nib_credit_value_adj / 100;
      min = min * settings.nib_credit_minimum_adj / 100;
    }
    return { min, max };
  }, [settings, adjValue]);

  const cash = React.useMemo((): { min: number, max: number } => {
    let min = adjValue;
    let max = adjValue;
    if (settings) {
      max = max * settings.nib_cash_value_adj / 100;
      min = min * settings.nib_cash_minimum_adj / 100;
    }
    return { min, max };
  }, [settings, adjValue]);

  const margin = (c: number, v: number): number => {
    return Math.round(100 - c / v * 100);
  };

  if (settings) {

    return (
      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="top" spacing={1}>
          {infoBar &&
            <Grid item xs={12} md={12}>
              <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
            </Grid>
          }
          <Grid item xs={12} sx={{ p: 1, mb: 2 }}>
            <Typography variant="body2">
              Each percentage setting will modify the value of either the retail value or suggested offer. Amounts over 100% will increase the value and/or offer. Each modifier
              acts independently as a <em>discount</em>. For example, for a $100 set, an open box modifer of 90% will lower the retail value to $90, if the box is also damaged,
              the retail value will be lowered to $80.
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={1.5}>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The initial retail value of the set to use. Currently, Bricklink pricing data is updated about once a month`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Initial Retail Value</Grid>
              <Grid item xs={4}>
                <DefaultPriceSelector value={settings.default_nib_price} onSelect={(value) => updateDefaultPrice('default_nib_price', value)} />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Define the maximum percentage you are willing to offer for credit and cash based on the resale value.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Max percentage to offer</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Credit"
                  fullWidth
                  value={settings.nib_credit_value_adj}
                  onChange={(value) => updateSetting('nib_credit_value_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Cash"
                  fullWidth
                  value={settings.nib_cash_value_adj}
                  onChange={(value) => updateSetting('nib_cash_value_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for the starting credit and cash to be offered, for example for a $10 item, a 50% discount would mean the starting credit offer would be $5. `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Starting percentage to offer</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Credit"
                  fullWidth
                  value={settings.nib_credit_minimum_adj}
                  onChange={(value) => updateSetting('nib_credit_minimum_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Cash"
                  fullWidth
                  value={settings.nib_cash_minimum_adj}
                  onChange={(value) => updateSetting('nib_cash_minimum_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Typography variant="body2">
                  The following modifiers will lower the retail value of the set.
                </Typography>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Max percentage of the original retail value to offer if box is damaged.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Damaged</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.nib_damaged_adj}
                  onChange={(value) => updateSetting('nib_damaged_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Max percentage of the original retail value to offer if box is open. `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Open Box</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.nib_open_adj}
                  onChange={(value) => updateSetting('nib_open_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Typography variant="body2">
                  The following modifiers will not modify the retail value, these will only alter the cash/credit offer.
                </Typography>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The Max percentage to offer for excess stock , enter 100% if you do not want to discount for overstock.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Overstock</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.nib_overstock_adj}
                  onChange={(value) => updateSetting('nib_overstock_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The Max percentage to offer for a hot seller, a number above 100% will increase the credit/cash offer.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Hot Seller</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth={true}
                  value={settings.nib_hot_seller_adj || 0}
                  onChange={(value) => updateSetting('nib_hot_seller_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The max percentage to offer for a set that may sit more than a month, a number above 100% will increase the credit/cash offer.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Mediocre Seller</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth={true}
                  value={settings.nib_mediocre_adj || 0}
                  onChange={(value) => updateSetting('nib_mediocre_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The max percentage to offer for a set that may sit for many months`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Dust Collector</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth={true}
                  value={settings.nib_dust_collector_adj || 0}
                  onChange={(value) => updateSetting('nib_dust_collector_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The max percentage to offer for a set that may end up being parted out as bulk`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Not Interested</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth={true}
                  value={settings.nib_not_interested_adj}
                  onChange={(value) => updateSetting('nib_not_interested_adj', value)}
                />
              </Grid>
              <Box width="100%" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} sx={{ pl: 1 }}>
              <Grid item xs={12} md={12}>
                <Button variant="contained" fullWidth onClick={saveSettings}>Save</Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button fullWidth onClick={resetSettings}>Reset to default settings</Button>
                <ConfirmationDialog show={showResetConfirm} onClose={handleResetConfirm} message="Are you sure you want to reset your settings?" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ fontStyle: 'italic', px: 2, border: '1px solid #ccc' }}>
                  <h4>Test/Preview your settings</h4>
                  <p><Checkbox checked={openPreview} label="Open Box" onChange={(e) => setOpenPreview(e.target.checked)} /></p>
                  <p><Checkbox checked={damagedPreview} label="Damaged" onChange={(e) => setDamagedPreview(e.target.checked)} /></p>
                  <p><Checkbox checked={overstockPreview} label="Overstock" onChange={(e) => setOverstockPreview(e.target.checked)} /></p>
                  <p><DesirabilitySelector value={desirabilityPreview} onSelect={(value) => setDesirabilityPreview(value)} /></p>
                  <p>Estimated Retail Value: $50.00</p>
                  <p>Adjusted Retail Value: {formatCurrency(Math.round(value))}</p>
                  <p>Credit: {formatCurrency(Math.round(credit.min))}({margin(credit.min, value)}%) - {formatCurrency(Math.round(credit.max))}({margin(credit.max, value)}%)</p>
                  <p>Cash: {formatCurrency(Math.round(cash.min))} - {formatCurrency(Math.round(cash.max))}</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper >
    );
  } else {
    return (<></>);
  }
};
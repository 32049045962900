import * as React from 'react';
import { Button, ButtonGroup, Grid, IconButton, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { MiscPriceBand } from 'model';


interface Props {
  bands: MiscPriceBand[];
  onAddMisc: (name: string) => void;
  onAddBand: (band: MiscPriceBand) => void;
  onCancel: () => void;
}

export const MiscSelector: React.FC<Props> = ({ bands, onAddBand, onAddMisc, onCancel }: Props) => {

  const [name, setName] = React.useState<string>("");

  const addItem = () => {
    onAddMisc(name);
  };

  const selectBand = (band: MiscPriceBand) => {
    onAddBand(band);
  };

  const updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12} md="auto">
        <h4>Add New:</h4>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField label="Description" variant="outlined" size="small" fullWidth onChange={updateName} value={name} />
      </Grid>
      <Grid item xs={12} md="auto">
        <Button onClick={addItem}>Add</Button>
      </Grid>
      <Grid item xs={12} md="auto">
        <ButtonGroup variant="contained" aria-label="misc selector">
          {bands.map((band) => band.active ? <Button key={band.id} onClick={() => selectBand(band)}>{band.name}</Button> : <></>)}
        </ButtonGroup>
      </Grid>
      <Grid item xs={1} md={1}>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
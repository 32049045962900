import * as React from "react";
import { api } from "lib";

interface Props {
  status: string;
  logout: () => void;
}

export const useLogout = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const logout = async () => {
    setStatus(api.loading);
    const resp = await api.logout();
    if (resp) {
      setStatus(api.success);
    } else {
      setStatus(api.error);
    }
  };

  return {
    status,
    logout,
  };
};

import * as React from 'react';
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useLoadBuySettings } from 'api';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { Checkbox, CompletenessSelector, ConfirmationDialog, DefaultPriceSelector, DesirabilitySelector, InfoBar, PercentageInput } from 'components';
import { BuySettingModel, InfoBarModel } from 'model';
import { useSaveBuySettings } from 'api/buy/save_settings';

export const UsedBuySettingsTab: React.FC = () => {
  const { currentUnit, currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { load, settings: s, status } = useLoadBuySettings();
  const { saveBuySettings } = useSaveBuySettings();
  const [settings, setSettings] = React.useState<BuySettingModel | undefined>();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const [completenessPreview, setCompletenessPreview] = React.useState<string>("ready_to_sell");
  const [dirtyPreview, setDirtyPreview] = React.useState<boolean>(false);
  const [overstockPreview, setOverstockPreview] = React.useState<boolean>(false);
  const [desirabilityPreview, setDesirabilityPreview] = React.useState<string>("good_seller");
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    }
  }, [currentUnit, currentUser, load]);

  React.useEffect(() => {
    if (status === api.success && s?.settings) {
      setSettings(s.settings);
    }
  }, [s, status]);

  React.useEffect(() => {
    if (saveBuySettings.status === api.success && saveBuySettings.settings?.settings) {
      setSettings(saveBuySettings.settings.settings);
    }
  }, [saveBuySettings]);

  const updateDefaultPrice = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: value };
      setSettings(newSettings);
    }
  };

  const updateSetting = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: +value };
      setSettings(newSettings);
    }
  };

  React.useEffect(() => {
    if (saveBuySettings.status === api.success) {
      setInfoBar({ status: "success", message: 'Settings saved' });
    } else if (saveBuySettings.status === api.error) {
      setInfoBar({ status: "error", message: saveBuySettings.msg });
    } else {
      setInfoBar(null);
    }
  }, [saveBuySettings.status, saveBuySettings.msg, setInfoBar]);

  const [showResetConfirm, setShowResetConfirm] = React.useState<boolean>(false);
  const resetSettings = () => setShowResetConfirm(true);
  const handleResetConfirm = (value: boolean) => {
    setShowResetConfirm(false);
    if (value && currentUnit) {
      saveBuySettings.reset(currentUnit.id, 'minifigs');
    }
  };

  const saveSettings = () => {
    if (settings && s) {
      saveBuySettings.save({ ...s, settings: settings });
    }
  };

  const adjValue = React.useMemo(() => {
    let value = 100;
    let discount = 0;
    if (settings) {
      if (settings.used_compl_ready_to_sell_adj && completenessPreview === "ready_to_sell") {
        discount = value * (1 - settings.used_compl_ready_to_sell_adj / 100);
      } else if (settings.used_compl_minor_work_needed_adj && completenessPreview === "minor_work_needed") {
        discount = value * (1 - settings.used_compl_minor_work_needed_adj / 100);
      } else if (settings.used_compl_partial_build_adj && completenessPreview === "partial_build") {
        discount = value * (1 - settings.used_compl_partial_build_adj / 100);
      } else if (settings.used_compl_unbuilt_adj && completenessPreview === "unbuilt") {
        discount = value * (1 - settings.used_compl_unbuilt_adj / 100);
      }
    }
    return value - discount;
  }, [settings, completenessPreview]);

  const offerValues = React.useMemo(() => {
    let creditMin = 0;
    let creditMax = 0;
    let cashMin = 0;
    let cashMax = 0;
    let offerDiscount = 0;
    if (settings) {
      if (settings.used_dirty_adj && dirtyPreview) {
        offerDiscount += adjValue * (1 - settings.used_dirty_adj / 100);
      }
      if (settings.used_overstock_adj && overstockPreview) {
        offerDiscount += adjValue * (1 - settings.used_overstock_adj / 100);
      }
      if (settings.used_hot_seller_adj && desirabilityPreview === "hot_seller") {
        offerDiscount += adjValue * (1 - settings.used_hot_seller_adj / 100);
      } else if (settings.used_mediocre_adj && desirabilityPreview === "mediocre_seller") {
        offerDiscount += adjValue * (1 - settings.used_mediocre_adj / 100);
      } else if (settings.used_dust_collector_adj && desirabilityPreview === "slow_seller") {
        offerDiscount += adjValue * (1 - settings.used_dust_collector_adj / 100);
      } else if (settings.used_not_interested_adj && desirabilityPreview === "not_interested") {
        offerDiscount += adjValue * (1 - settings.used_not_interested_adj / 100);
      }
      const offerValue = adjValue - offerDiscount < 0 ? 0 : adjValue - offerDiscount;
      creditMin = offerValue * (settings.used_credit_minimum_adj / 100);
      creditMax = offerValue * (settings.used_credit_value_adj / 100);
      cashMin = offerValue * (settings.used_cash_minimum_adj / 100);
      cashMax = offerValue * (settings.used_cash_value_adj / 100);
    }
    return {
      credit: {
        min: creditMin,
        max: creditMax,
      },
      cash: {
        min: cashMin,
        max: cashMax,
      }
    }
  }, [settings, adjValue, desirabilityPreview, overstockPreview, dirtyPreview]);

  if (settings) {
    return (
      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="top" spacing={1}>
          {infoBar &&
            <Grid item xs={12} md={12}>
              <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
            </Grid>
          }
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={1.5}>
              <Grid item xs={12} sx={{ p: 1, mb: 2 }}>
                <Typography variant="body2">
                  Each percentage setting will modify the value of either the retail value or suggested offer. Amounts over 100% will increase the value and/or offer. Each modifier
                  acts independently as a <em>discount</em>. For example, for a $100 set, a minor work needed setting of 80% will lower the retail value to $80.
                </Typography>
              </Grid>
              <Grid item xs={0.30}>
                <Tooltip title={`The initial retail value of the set to use. Currently, Bricklink pricing data is updated about once a month`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Initial Retail Value</Grid>
              <Grid item xs={4}>
                <DefaultPriceSelector value={settings.default_used_price} onSelect={(value) => updateDefaultPrice('default_used_price', value)} />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Define the maximum percentage you are willing to offer for credit and cash based on the resale value.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Max Percentage to Offer</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Credit"
                  fullWidth
                  value={settings.used_credit_value_adj}
                  onChange={(value) => updateSetting('used_credit_value_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Cash"
                  fullWidth
                  value={settings.used_cash_value_adj}
                  onChange={(value) => updateSetting('used_cash_value_adj', value)}
                />
              </Grid>
              <Grid item xs="auto">
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount from the maximum credit offered, for example, if $2 is being offered for a $10 item, a 50% discount would mean the minimum credit offer would be $1. `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Starting Percentage to Offer</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Credit"
                  fullWidth
                  value={settings.used_credit_minimum_adj}
                  onChange={(value) => updateSetting('used_credit_minimum_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Cash"
                  fullWidth
                  value={settings.used_cash_minimum_adj}
                  onChange={(value) => updateSetting('used_cash_minimum_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              {/* <Grid item xs={0.30}>
                <Tooltip title={`This adjustment does not impact the offer price. You can add an additional adjustment to the final cost of a set to account for labor, etc if you are importing into the POS `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Cost Modifier</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_additional_cost_adj}
                  onChange={(value) => updateSetting('used_additional_cost_adj', value)}
                />
              </Grid> */}
              <Box width="100%" />
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Typography variant="body2">
                  The following modifiers will modify the retail value. This helps account for the fact that you may choose to sell these sets at a lower value because they are not complete.
                </Typography>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to boost or discount for a set that can be put up for sale ASAP.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Ready to sell</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_compl_ready_to_sell_adj}
                  onChange={(value) => updateSetting('used_compl_ready_to_sell_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for a set that can needs minor work to sell as a complete set.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Minor work needed</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_compl_minor_work_needed_adj}
                  onChange={(value) => updateSetting('used_compl_minor_work_needed_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for a set that will require significant work to complete, most likely bulked.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Partial Build</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_compl_partial_build_adj}
                  onChange={(value) => updateSetting('used_compl_partial_build_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for a set that is unbuilt which should be considered bulk in almost all cases.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Unbuilt</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_compl_unbuilt_adj}
                  onChange={(value) => updateSetting('used_compl_unbuilt_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Typography variant="body2">
                  The following modifiers will only modify the offer value.
                </Typography>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount if set requires cleaning.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Dirty</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_dirty_adj}
                  onChange={(value) => updateSetting('used_dirty_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for excess stock , enter 0% if you do not want to discount for overstock.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Overstock</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_overstock_adj}
                  onChange={(value) => updateSetting('used_overstock_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount or markup your offer for a set that may sell in a few days (to markup enter a percentage higher than 100%)`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Hot Seller</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_hot_seller_adj}
                  onChange={(value) => updateSetting('used_hot_seller_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for a set that may sit more than a month`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Mediocre Seller</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_mediocre_adj}
                  onChange={(value) => updateSetting('used_mediocre_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for a set that may sit for many months`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Dust Collector</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_dust_collector_adj}
                  onChange={(value) => updateSetting('used_dust_collector_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for a set that may end up being parted out as bulk`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={5}>Not Interested</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.used_not_interested_adj}
                  onChange={(value) => updateSetting('used_not_interested_adj', value)}
                />
              </Grid>
              <Box width="100%" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} sx={{ pl: 2 }}>
              <Grid item xs={12} md={12}>
                <Button variant="contained" fullWidth onClick={saveSettings}>Save</Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button fullWidth onClick={resetSettings}>Reset to default settings</Button>
                <ConfirmationDialog show={showResetConfirm} onClose={handleResetConfirm} message="Are you sure you want to reset your settings?" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ px: 2, pb: 2, border: '1px solid #ccc' }}>
                  <h4>Test/Preview your settings</h4>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>Completeness</Grid>
                    <Grid item xs={8}><CompletenessSelector value={completenessPreview} onSelect={(value) => setCompletenessPreview(value)} /></Grid>
                    <Grid item xs={4}>Desirability</Grid>
                    <Grid item xs={8}><DesirabilitySelector value={desirabilityPreview} onSelect={(value) => setDesirabilityPreview(value)} /></Grid>
                    <Grid item xs={12}><Checkbox checked={dirtyPreview} variant="body2" label="Dirty" onChange={(e) => setDirtyPreview(e.target.checked)} /></Grid>
                    <Grid item xs={12}><Checkbox checked={overstockPreview} variant="body2" label="Overstock" onChange={(e) => setOverstockPreview(e.target.checked)} /></Grid>
                    <Grid item xs={7}>Estimated Resale Value:</Grid>
                    <Grid item xs={5}>{formatCurrency(100, false)}</Grid>
                    <Grid item xs={7}>Adjusted Resale Value:</Grid>
                    <Grid item xs={5}>{formatCurrency(adjValue, false)}</Grid>
                    <Grid item xs={7}>Credit Offer:</Grid>
                    <Grid item xs={5}>{formatCurrency(offerValues.credit.min, false)} - {formatCurrency(offerValues.credit.max, false)}</Grid>
                    <Grid item xs={7}>Cash Offer:</Grid>
                    <Grid item xs={5}>{formatCurrency(offerValues.cash.min, false)} - {formatCurrency(offerValues.cash.max, false)}</Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper >
    );
  } else {
    return (<></>);
  }
};
import * as React from 'react';
import { TextField, InputAdornment } from "@mui/material";
import { debounce } from 'lodash';

interface PercentageInputProps {
  label: string;
  value: number;
  max100?: boolean;
  fullWidth?: boolean;
  onChange: (value: number) => void;
}

export const PercentageInput: React.FC<PercentageInputProps> = ({ label, value, onChange, max100 = false, fullWidth = false }: PercentageInputProps) => {
  const [percentage, setPercentage] = React.useState<number>(value);
  const doUpdate = React.useMemo(() => debounce(onChange, 500), [onChange]);

  const update = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value) || 0;
    if (value > 100 && max100) {
      value = 100;
    } else if (value < 0) {
      value = 0;
    }
    setPercentage(value);
    doUpdate(value);
  };

  const sx = React.useMemo(() => fullWidth ? {} : { width: 80 }, [fullWidth]);

  return (
    <TextField
      label={label}
      type="number"
      value={percentage}
      size="small"
      fullWidth={fullWidth}
      onChange={update}
      onFocus={event => event.target.select()}
      InputProps={{
        style: { fontSize: '0.9rem' },
        endAdornment: <InputAdornment position="end" sx={{ padding: 0, margin: 0 }}>%</InputAdornment>,
      }}
      sx={sx}
    />
  );
};

import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TextField, Button, Link, LinearProgress, Grid } from '@mui/material';
import { CountrySelector, HeartlandSelector, InfoBar, NotFound, UserSelector, StateSelector } from 'components';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import CancelIcon from '@mui/icons-material/Cancel';

import { SessionContext, TSessionContext, api } from 'lib';
import { HeartlandModel, InfoBarModel, UnitModel } from 'model';

import useStyles from './styles';
import { useGetUnit, useUpdateUnit } from './api';

interface FormProps {
  unit: UnitModel;
  adminView: boolean;
  message?: string;
}

const UnitForm: React.FC<FormProps> = (props: FormProps) => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const { state } = useLocation();
  const [unit, setUnit] = React.useState<UnitModel>({ ...props.unit });
  const { status, error, update } = useUpdateUnit();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);

  const updateUnit = (prop: string, value: string | number | HeartlandModel) => setUnit({ ...unit, [prop]: value });
  const clearInfoBar = () => setInfoBar(null);
  const reset = () => setUnit({ ...props.unit });
  const doUpdate = () => update(unit);

  const updateCountry = (value: string) => updateUnit('country', value);
  const updateState = (value: string) => updateUnit('state', value);

  React.useEffect(() => {
    if (state && state.message) {
      setInfoBar({ status: state.type, message: state.message });
    } else if (status === api.success) {
      setInfoBar({ status: "success", message: "Unit updated!" });
    } else if (status === api.error) {
      setInfoBar({ status: "error", message: error });
    } else {
      setInfoBar(null);
    }
  }, [state, error, status, setInfoBar]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <h2><Link href="/units">{isAdmin() ? 'Units' : 'Locations'}</Link> :: {unit.name}</h2>
      </Grid>
      <Grid item md={12} xs={12}>
        {infoBar &&
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        }
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>
            {props.adminView ?
              <TextField
                id="franchise_id"
                label="Franchise ID"
                variant="outlined"
                fullWidth
                value={unit.franchise_id}
                onChange={(e) => updateUnit('franchise_id', e.target.value)}
              />
              :
              <TextField
                id="franchise_id"
                label="Franchise ID"
                variant="outlined"
                fullWidth
                disabled
                value={unit.franchise_id}
                inputProps={{ readOnly: true }}
              />
            }
          </Grid>
          <Grid item md={10} xs={12}>
            {props.adminView ?
              <UserSelector value={unit.owner} />
              :
              <></>
            }
          </Grid>
          <Grid item md={6} xs={12}>
            {props.adminView ?
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                fullWidth
                value={unit.name}
                onChange={(e) => updateUnit('name', e.target.value)}
              />
              :
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                fullWidth
                value={unit.name}
                disabled
                inputProps={{ readOnly: true }}
              />
            }
          </Grid>
          <Grid item md={6} xs={12}>
            {props.adminView ?
              <HeartlandSelector
                value={unit.heartland}
                onSelect={(value) => updateUnit('heartland', value)}
              /> :
              <TextField
                id="heartland"
                label="Heartland Domain"
                variant="outlined"
                fullWidth
                value={unit.heartland?.domain}
                disabled
                inputProps={{ readOnly: true }}
              />
            }
          </Grid>
          <Grid item md={9} xs={12}>
            <TextField
              id="address"
              label="Address"
              variant="outlined"
              fullWidth
              value={unit ? unit.address : ""}
              onChange={(e) => updateUnit('address', e.target.value)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              id="address2"
              label="Address (Unit/Suite)"
              variant="outlined"
              fullWidth
              value={unit ? unit.address2 : ""}
              onChange={(e) => updateUnit('address2', e.target.value)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id="city"
              label="City"
              variant="outlined"
              fullWidth
              value={unit ? unit.city : ""}
              onChange={(e) => updateUnit('city', e.target.value)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <StateSelector
              value={unit.state}
              country={unit.country}
              onSelect={updateState}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              id="postal"
              label="Postal/Zip Code"
              variant="outlined"
              fullWidth
              value={unit ? unit.postal_code : ""}
              onChange={(e) => updateUnit('postal_code', e.target.value)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CountrySelector
              value={unit.country}
              onSelect={updateCountry}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="phone"
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={unit ? unit.phone : ""}
              onChange={(e) => updateUnit('phone', e.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              value={unit ? unit.email : ""}
              onChange={(e) => updateUnit('email', e.target.value)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              id="heartland_name"
              label="Heartland Location Name"
              variant="outlined"
              fullWidth
              value={unit ? unit.heartland_name : ""}
              onChange={(e) => updateUnit('heartland_name', e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} >
            <Button
              variant="contained"
              aria-label="Save Edits"
              startIcon={<AddBusinessIcon />}
              onClick={doUpdate}
              fullWidth
            >
              Save
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
            <Button
              variant="contained"
              aria-label="Reset"
              startIcon={<RestorePageIcon />}
              onClick={reset}
              fullWidth
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Cancel"
              startIcon={<CancelIcon />}
              href="/units"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const UnitShow: React.FC = () => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;

  const { unit, status, get } = useGetUnit();
  const { unit_id } = useParams();
  const classes = useStyles();

  React.useEffect(() => {
    if (unit_id) {
      get(parseInt(unit_id));
    }
  }, [unit_id, get]);

  if (status === api.loading) {
    return (<div style={{ marginTop: 32, width: 800 }}><LinearProgress /></div>);
  } else if (status === api.success && unit) {
    return (
      <div className={classes.show}>
        <UnitForm unit={unit} adminView={isAdmin()} />
      </div>
    );
  } else if (status === api.error) {
    return (<NotFound />);
  }
  return (<></>);
};
import * as React from "react";

import { Alert, Box, Button, Grid, Link, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ReactToPrint from "react-to-print";

import { BuyModel } from "model";
import { SessionContext, TSessionContext, formatCurrency, formatPercentage } from "lib";
import { NewSetSection } from "./components/show/new_set_section";
import { UsedSetSection } from "./components/show/used_set_section";
import { MinifigSection } from "./components/show/minifig_section";
import { BulkSection } from "./components/show/bulk_section";
import { ImportList, PurchaseOrderImpot } from "./components/show/csv";
import { MiscSection } from "./components/show/misc_section";
import { ProcessingTags } from "./components/show/processing_tags";
import moment from "moment";
import { Checkbox } from "components";

interface Props {
  buy: BuyModel;
}

const BuySheetContents = ({ buy }: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;

  const formatDate = (data: string | undefined) => {
    if (data) {
      const date = new Date(data);
      return date.toLocaleString();
    }
    return "";
  };

  const totalPaid = React.useMemo(() => {
    if (buy) {
      return buy.buy_type === "cash" ? buy.cash_paid : buy.credit_paid;
    } else {
      return 0;
    }
  }, [buy]);

  const bulkPaid = React.useMemo(() => {
    if (buy) {
      return (buy.buy_type === "cash" ? buy.bulk_cash_paid : buy.bulk_credit_paid);
    } else {
      return 0;
    }
  }, [buy]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5"><Link href="/buys">Buys</Link> :: {buy.id} </Typography>
      </Grid>
      {
        buy.status === "cancelled" &&
        <>
          <Grid item xs={12}>
            <Alert severity="error">CANCELLED: {formatDate(buy.updated_at)} </Alert>
          </Grid>
          <Grid item xs={12}>
            Employee: {buy.creator?.first_name} {buy.creator?.last_name}
          </Grid>
        </>
      }
      <Grid item xs={3}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                Customer:
              </TableCell>
              <TableCell>
                {buy.customer?.first_name} {buy.customer?.last_name} (<Link href={`https://${currentHeartland?.domain}.retail.heartland.us/#customers/edit/${buy.customer?.external_id}`}>{buy.customer?.external_id}</Link>)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone:</TableCell>
              <TableCell>{buy.customer?.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>EMail:</TableCell>
              <TableCell>{buy.customer?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address:</TableCell>
              <TableCell>{buy.customer?.address?.line_1} {buy.customer?.address?.line_2}, {buy.customer?.address?.city} {buy.customer?.address?.state} {buy.customer?.address?.postal_code}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={3}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>{buy.unit.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completed At</TableCell>
              <TableCell>{formatDate(buy.updated_at)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Employee</TableCell>
              <TableCell>{buy.creator?.first_name} {buy.creator?.last_name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={3}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>
                {buy.buy_type === "cash" ? "Cash" : "Store Credit"}
                {buy.buy_type === "cash" && buy.cash_type && ` (${buy.cash_type} ${buy.cash_reference})`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Paid</TableCell>
              <TableCell>{formatCurrency(totalPaid)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Asking</TableCell>
              <TableCell>{formatCurrency(buy.asking)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Offer</TableCell>
              <TableCell> {formatCurrency(buy.buy_type === "cash" ? buy.cash_offered : buy.credit_offered)} </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={3}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Estimated Retail Value</TableCell>
              <TableCell>{formatCurrency(bulkPaid === 0 ? buy.total_retail - buy.total_bulk_value : buy.total_retail)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Estimated Margin</TableCell>
              <TableCell> {formatPercentage(1 - (totalPaid - bulkPaid) / buy.total_retail)} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Estimated Bulk Retail Value</TableCell>
              <TableCell>{formatCurrency(buy.total_bulk_value)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Estimated Margin</TableCell>
              <TableCell> {bulkPaid > 0 ? formatPercentage(1 - bulkPaid / buy.total_bulk_value) : "N/A"} </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      {
        buy.buy_store_credit_log &&
        <Grid item xs={12}>
          Store Credit Deposit Status: {buy.buy_store_credit_log.status} at {formatDate(buy.buy_store_credit_log.completed_at)} for {formatCurrency(buy.buy_store_credit_log.amount)}
        </Grid>
      }
      <Grid item xs={12}>
        {buy.new_set_buy_lines.length > 0 && <NewSetSection buy={buy} />}
        {buy.used_set_buy_lines.length > 0 && <UsedSetSection buy={buy} />}
        {(buy.minifig_buy_lines.length > 0 || buy.series_minifig_buy_lines.length > 0) && <MinifigSection buy={buy} />}
        {buy.bulk_buy_lines.length > 0 && <BulkSection buy={buy} />}
        {buy.misc_buy_lines.length > 0 && <MiscSection buy={buy} />}
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <h4>Notes</h4>
          {buy.notes.map((note) =>
            <Grid container spacing={1} key={note.id} sx={{ my: 2, pb: 2, borderBottom: '1px solid #ccc' }}>
              <Grid item xs={12}>
                {note.note}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption"> by {note.user.display_name}, {moment(note.created_at).fromNow()}</Typography>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid >
  );
};

const Printable = styled('div')({
  '@media print': {
    margin: '48px 36px 0 36px'
  }
});

export const BuySheet = ({ buy }: Props) => {
  const componentRef = React.useRef<HTMLDivElement>(null)
  const processingRef = React.useRef<HTMLDivElement>(null)
  const [importList, setImportList] = React.useState<ImportList>({ new_sets: true, used_sets: true, minifigs: true, cmf: true });

  const updateImportList = (key: string, checked: boolean) => {
    setImportList({ ...importList, [key]: checked });
  };

  return (
    <Box sx={{ mt: 2, ml: 2, mb: 10, width: "90%" }}>
      <Printable ref={componentRef}>
        <BuySheetContents buy={buy} />
      </Printable>
      <Box sx={{ display: "none" }}>
        <Printable ref={processingRef}>
          <ProcessingTags buy={buy} />
        </Printable>
      </Box>
      <Grid container justifyContent="center" spacing={3} sx={{ my: 4 }}>
        <Grid item>
          <ReactToPrint
            trigger={() => <Button variant="contained" color="primary">Print</Button>}
            content={() => componentRef.current}
          />
        </Grid>
        {buy.status !== "cancelled" &&
          <>
            <Grid item>
              <ReactToPrint
                trigger={() => <Button variant="contained" color="primary">Print Processing Tags</Button>}
                content={() => processingRef.current}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" href={`/buys/${buy.id}?view=form`}>View Completed Buy Sheet</Button>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center", mx: "auto" }}>
                <Checkbox label="New Sets" checked={importList.new_sets} onChange={(e) => updateImportList("new_sets", e.target.checked)} />
                <Checkbox label="Used Sets" checked={importList.used_sets} onChange={(e) => updateImportList("used_sets", e.target.checked)} />
                <Checkbox label="CMF" checked={importList.cmf} onChange={(e) => updateImportList("cmf", e.target.checked)} />
                <Checkbox label="Minifigs" checked={importList.minifigs} onChange={(e) => updateImportList("minifigs", e.target.checked)} />
                <PurchaseOrderImpot buy={buy} importList={importList} />
              </Box>
            </Grid>
          </>
        }
      </Grid>
    </Box>
  );
};
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import React from "react";
import { formatCurrency } from "lib";
import { BuyModel, MiscBuyLineModel } from "model";
import { cost } from "./helpers";

interface Props {
  buy: BuyModel;
}

export const MiscSection: React.FC<Props> = ({ buy }: Props) => {
  let total = 0;

  const getRows: MiscBuyLineModel[] = React.useMemo(() => {
    return buy.misc_buy_lines.filter((row) => !row.deleted);
  }, [buy.misc_buy_lines]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Miscellaneous</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Misc">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell width={50} align="right">Qty</TableCell>
            <TableCell width={80} align="right">Cost</TableCell>
            <TableCell width={80} align="right">Value</TableCell>
            <TableCell width={80} align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => {
            total += row.cost_final * row.quantity;
            return (
              <TableRow key={row.id}>
                <TableCell>{row.description}<br />{row.notes}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{formatCurrency(row.cost_final)}</TableCell>
                <TableCell align="right">{formatCurrency(row.value)}</TableCell>
                <TableCell align="right">{formatCurrency(row.value)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{buy.total_misc_qty}</TableCell>
            <TableCell align="right">{formatCurrency(total)}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{formatCurrency(buy.total_misc_value)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
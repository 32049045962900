import * as React from "react";
import { api } from "lib";
import { BuyModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  buysList: {
    status: string;
    error: string;
    buys: BuyModel[];
    total: number;
  };
  listBuys: (status: string, page: number, size: number) => void;
  listBuysByCustomer: (customerId: number, page: number, size: number) => void;
  listBuysByNewSets: (legoSetId: number, page: number, size: number) => void;
  listBuysByUsedSets: (legoSetId: number, page: number, size: number) => void;
}

interface State {
  status: string;
  error: string;
  buys: BuyModel[];
  total: number;
}

const defaultState: State = {
  status: api.idle,
  error: '',
  buys: [],
  total: 0
};

export const useBuysList = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const list = React.useCallback(async (status = "", page = 0, pageSize = 0, sort = 'updated_at', direction = 'desc') => {
    setState((prevState) => ({ ...prevState, status: api.loading }));
    const options = {
      status: status,
      page: page.toString(),
      size: pageSize.toString(),
      sort: sort,
      direction: direction,
    }
    const resp = await api.get('/api/buys', options);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, buys: resp.body.buys, total: resp.body.total, error: '' });
    } else {
      setState({ status: api.error, buys: [], total: 0, error: resp.body });
    }
  }, [setState]);

  const listByCustomer = React.useCallback(async (customerId: number, page = 0, pageSize = 0, sort = 'updated_at', direction = 'desc') => {
    setState((prevState) => ({ ...prevState, status: api.loading }));
    const options = {
      heartland_customer_id: `${customerId}`,
      page: page.toString(),
      size: pageSize.toString(),
      sort: sort,
      direction: direction,
    }
    const resp = await api.get(`/api/customers/${customerId}/buys`, options);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, buys: resp.body.buys, total: resp.body.total, error: '' });
    } else {
      setState({ status: api.error, buys: [], total: 0, error: resp.body });
    }
  }, [setState]);

  const listByNewSets = React.useCallback(async (legoSetId: number, page = 0, pageSize = 0, sort = 'updated_at', direction = 'desc') => {
    setState((prevState) => ({ ...prevState, status: api.loading }));
    const options = {
      page: page.toString(),
      size: pageSize.toString(),
      sort: sort,
      direction: direction,
    }
    const resp = await api.get(`/api/lego_sets/${legoSetId}/buys/new`, options);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, buys: resp.body.buys, total: resp.body.total, error: '' });
    } else {
      setState({ status: api.error, buys: [], total: 0, error: resp.body });
    }
  }, [setState]);

  const listByUsedSets = React.useCallback(async (legoSetId: number, page = 0, pageSize = 0, sort = 'updated_at', direction = 'desc') => {
    setState((prevState) => ({ ...prevState, status: api.loading }));
    const options = {
      page: page.toString(),
      size: pageSize.toString(),
      sort: sort,
      direction: direction,
    }
    const resp = await api.get(`/api/lego_sets/${legoSetId}/buys/used`, options);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, buys: resp.body.buys, total: resp.body.total, error: '' });
    } else {
      setState({ status: api.error, buys: [], total: 0, error: resp.body });
    }
  }, [setState]);

  return {
    buysList: {
      ...state,
    },
    listBuys: list,
    listBuysByCustomer: listByCustomer,
    listBuysByNewSets: listByNewSets,
    listBuysByUsedSets: listByUsedSets,
  }
};

import * as React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { InfoBar } from "components";
import { api } from "lib";
import { useBuysList } from "api/buy";
import { BuyColumns } from "components/tables";


interface Props {
  status: string;
}

const BuysTable = ({ status }: Props) => {
  const { buysList, listBuys } = useBuysList();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  React.useEffect(() => {
    listBuys(status, paginationModel.page, paginationModel.pageSize);
  }, [paginationModel, listBuys, status]);

  if (buysList.status === api.error) {
    return (<InfoBar status="error" message={buysList.error} />);
  }
  return (
    <DataGrid
      rows={buysList.buys}
      columns={BuyColumns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 25 },
        },
      }}
      loading={buysList.status === api.loading}
      slots={{
        loadingOverlay: LinearProgress,
      }}
      rowCount={buysList.total}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      paginationMode="server"
    />
  );
};

export const BuysList = () => {
  const [status, setStatus] = React.useState<string>('active');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setStatus(newValue);
  };
  return (
    <Box sx={{ width: '95%', pt: 2, fontSize: '0.9rem' }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Buys</Typography>
      <Tabs onChange={handleChange} value={status} aria-label="Buys" sx={{ mb: 2 }}>
        <Tab label="Active" value="active" sx={{ px: 8 }} />
        <Tab label="Pending" value="pending" sx={{ px: 8 }} />
        <Tab label="Complete" value="completed" sx={{ px: 8 }} />
        <Tab label="Cancelled" value="cancelled" sx={{ px: 8 }} />
      </Tabs>
      <BuysTable status={status} />
    </Box>
  );
};
import * as React from "react";
import { api } from "lib";
import { LegoSetModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  message: string;
  item: any;
}

interface Props {
  heartlandImport: State & {
    add: (legoSetId: number, heartlandId: number, params: Record<string, string>) => void,
    reset: () => void;
  };
}

const defaultState = {
  status: api.idle,
  message: "",
  item: {},
}

export const useHeartlandImport = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const add = React.useCallback(async (legoSetId: number, heartlandId: number, params: Record<string, any>) => {
    const payload = {
      public_id: params.itemNumber,
      heartland_id: heartlandId,
      cost: params.defaultCost,
      price: params.currentPrice,
      description: params.description,
      long_description: params.longDescription,
      active: true,
      custom: {
        ...params.custom,
        department: params.department === "new_sets" ? "New Sets" : "Used Sets",
      }
    };
    setState({ status: api.loading, message: '', item: null });
    const resp = await api.post(`/api/lego_sets/${legoSetId}/heartland_import`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, item: resp.body, message: '' });
    } else {
      setState({ status: api.error, item: null, message: resp.body });
    }
  }, []);

  const reset = React.useCallback(() => {
    setState({ ...defaultState });
  }, []);

  return {
    heartlandImport: { ...state, add, reset }
  };
};


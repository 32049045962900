import * as React from 'react';
import { TableCell } from '@mui/material';
import { styled } from "@mui/material/styles";

interface TCellProps {
  children?: React.ReactNode
  align?: 'left' | 'center' | 'right';
  colSpan?: number;
  width?: string;
  fontSize?: string;
}

const Cell: React.FC<TCellProps> = (props: TCellProps) => {
  const fontSize = React.useMemo(() => {
    return props.fontSize ? props.fontSize : '0.75rem';
  }, [props.fontSize]);
  return (
    <TableCell
      colSpan={props.colSpan ? props.colSpan : 1}
      align={props.align ? props.align : 'left'}
      width={props.width}
      sx={{ fontSize: `${fontSize}` }}
    >
      {props.children}
    </TableCell >
  );
};

export const TCell = styled(Cell)();
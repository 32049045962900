import * as React from "react";
import { api } from "lib";
import { CustomerModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  customer: CustomerModel | null;
  sync: (id: number) => void;
}

export const useSyncCustomer = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [customer, setCustomer] = React.useState<CustomerModel | null>(null);

  const sync = React.useCallback(async (customer_id: number) => {
    setStatus(api.loading);
    const resp = await api.get(`/api/customers/${customer_id}/sync`);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setCustomer(resp.body);
    } else if (resp.status === StatusCodes.NOT_FOUND) {
      setStatus(api.error);
      setCustomer(null);
      setError('Customer not found!');
    } else {
      setStatus(api.error);
      setCustomer(null);
      setError(resp.body.error);
    }
  }, []);

  return {
    status,
    error,
    customer,
    sync,
  };
};

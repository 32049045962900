import * as React from "react";
import { Paper, Grid, Typography, TextField } from "@mui/material";
import { BuyContext, TBuyContext } from "pages/buy/buy_context";

const sectionStyle = {
  mx: 1,
  my: 1,
  py: 3,
  px: 2,
  background: "#f6f6f6"
};

export const Signatures: React.FC = () => {
  const { buy, updateSignature } = React.useContext(BuyContext) as TBuyContext;

  return (
    <>
      <Paper sx={sectionStyle}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1 }}> Seller/Customer Signature</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}> Must be 18 years or older.  </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>I am fully aware that your store policy prohibits the purchase of new in box items that are still available from LEGO<sup>®</sup> to discourage the sale of stolen items, unless proof of purchase is provided. I hereby certify I am the owner of this property and have the legal right to sell it as described and listed above at the value agreed upon above.</Typography>
            <Typography variant="body2">By typing your name below, you are signing this agreement electronically. You agree that your electronic signature is the legal equivalent of your manual signature.</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              helperText={buy.status === "completed" ? "" : "Signature"}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: buy.status === "completed", style: { fontFamily: 'cursive', fontSize: "1.5em" } }}
              value={buy.customer_signature}
              onChange={((e) => updateSignature('customer_signature', e.target.value))}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={buy.status === "completed" ? "" : "Date"}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: buy.status === "completed", style: { fontFamily: 'cursive', fontSize: "1.5em" } }}
              value={buy.customer_signature_date}
              onChange={((e) => updateSignature('customer_signature_date', e.target.value))}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={sectionStyle}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1 }}>Employee Signature</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}> I certify that I have verified the identity and age of {buy.customer?.first_name} {buy.customer?.last_name} by reviewing government-issued photo identification. I also confirm that they are at least 18 years of age.</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              helperText={buy.status === "completed" ? "" : "Signature"}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: buy.status === "completed", style: { fontFamily: 'cursive', fontSize: "1.5em", } }}
              value={buy.employee_signature}
              onChange={((e) => updateSignature('employee_signature', e.target.value))}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              helperText={buy.status === "completed" ? "" : "Date"}
              variant="standard"
              fullWidth
              inputProps={{ readOnly: buy.status === "completed", style: { fontFamily: 'cursive', fontSize: "1.5em" } }}
              value={buy.employee_signature_date}
              onChange={((e) => updateSignature('employee_signature_date', e.target.value))}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
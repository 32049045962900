import * as React from "react";
import { api } from "lib";
import { DailyBuy } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  dailyBuysList: {
    status: string;
    error: string;
    buys: DailyBuy[];
  },
  getDailyBuysList: (startDate: string, endDate: string) => void;
}

interface State {
  status: string;
  error: string;
  buys: DailyBuy[];
}

const defaultState: State = {
  status: api.idle,
  error: '',
  buys: [],
};

export const useDailyBuysList = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = React.useCallback(async (startDate: string, endDate: string) => {
    setState(prevState => ({ ...prevState, status: api.loading }));
    const options = {
      start_date: startDate,
      end_date: endDate,
    };
    const resp = await api.get('/api/reports/buys/daily', options);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, buys: resp.body.buys, error: '' });
    } else {
      setState({ status: api.error, buys: [], error: resp.body });
    }
  }, [setState]);

  return {
    dailyBuysList: {
      ...state,
    },
    getDailyBuysList: get,
  };
};

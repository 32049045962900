import * as React from 'react';
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useLoadBuySettings } from 'api';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { Checkbox, ConfirmationDialog, DefaultPriceSelector, InfoBar, PercentageInput } from 'components';
import { BuySettingModel, InfoBarModel, MinifigPriceBand } from 'model';
import { useSaveBuySettings } from 'api/buy/save_settings';
import { MinifigPriceBandEntry } from './minifig_price_band_entry';

export const MinifigBuySettingsTab: React.FC = () => {
  const { currentUnit, currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { load, settings: s, status } = useLoadBuySettings();
  const { saveBuySettings } = useSaveBuySettings();
  const [settings, setSettings] = React.useState<BuySettingModel | undefined>();
  const [minifigPriceBands, setMinifigPriceBands] = React.useState<MinifigPriceBand[]>([]);
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const clearInfoBar = () => setInfoBar(null);
  const [damagedPreview, setDamagedPreview] = React.useState<boolean>(false);
  const [usedPreview, setUsedPreview] = React.useState<boolean>(false);
  const [incompletePreview, setIncompletePreview] = React.useState<boolean>(false);
  const [overstockPreview, setOverstockPreview] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    }
  }, [currentUnit, currentUser, load]);

  React.useEffect(() => {
    if (status === api.success && s?.settings) {
      setSettings(s.settings);
      setMinifigPriceBands(s.minifig_price_bands);
    }
  }, [s, status]);

  React.useEffect(() => {
    if (saveBuySettings.status === api.success && saveBuySettings.settings?.settings) {
      setSettings(saveBuySettings.settings.settings);
      setMinifigPriceBands(saveBuySettings.settings.minifig_price_bands);
    }
  }, [saveBuySettings]);

  const updateDefaultPrice = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: value };
      setSettings(newSettings);
    }
  };

  const updateSetting = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: +value };
      setSettings(newSettings);
    }
  };

  const updatePriceBand = (id: number, prop: string, value: string | number | boolean) => {
    const updatedBands = minifigPriceBands.map((b) => {
      if (b.id === id) {
        return { ...b, [prop]: value };
      } else {
        return b;
      }
    });
    setMinifigPriceBands(updatedBands);
  };

  React.useEffect(() => {
    if (saveBuySettings.status === api.success) {
      setInfoBar({ status: "success", message: saveBuySettings.msg });
    } else if (saveBuySettings.status === api.error) {
      setInfoBar({ status: "error", message: saveBuySettings.msg });
    } else {
      setInfoBar(null);
    }
  }, [saveBuySettings.status, saveBuySettings.msg, setInfoBar]);

  const [showResetConfirm, setShowResetConfirm] = React.useState<boolean>(false);
  const resetSettings = () => setShowResetConfirm(true);
  const handleResetConfirm = (value: boolean) => {
    setShowResetConfirm(false);
    if (value && currentUnit) {
      saveBuySettings.reset(currentUnit.id, 'minifigs');
    }
  };

  const saveSettings = () => {
    if (settings) {
      saveBuySettings.save({
        settings: settings,
        minifig_price_bands: minifigPriceBands,
        misc_price_bands: s.misc_price_bands,
        bulk_price_bands: s.bulk_price_bands
      });
    }
  };

  const adjValue = React.useMemo(() => {
    const value = 50;
    let discount = 0;
    if (settings) {
      if (damagedPreview) {
        discount += (1 - settings.minifig_damaged_adj / 100) * value;
      }
      if (incompletePreview) {
        discount += (1 - settings.minifig_incomplete_adj / 100) * value;
      }
      if (usedPreview) {
        discount += (1 - settings.minifig_used_adj / 100) * value;
      }
    }
    return value - discount;
  }, [settings, damagedPreview, incompletePreview, usedPreview]);

  const offerValues = React.useMemo(() => {
    let discount = 0;
    const offer = {
      credit: { min: adjValue, max: adjValue },
      cash: { min: adjValue, max: adjValue },
    }
    if (settings) {
      if (overstockPreview) {
        discount += (1 - settings.minifig_overstock_adj / 100) * adjValue;
      }
      const offerValue = adjValue - discount < 0 ? 0 : adjValue - discount;
      offer.credit.min = offerValue * settings.minifig_credit_minimum_adj / 100;
      offer.credit.max = offerValue * settings.minifig_credit_value_adj / 100;
      offer.cash.min = offerValue * settings.minifig_cash_minimum_adj / 100;
      offer.cash.max = offerValue * settings.minifig_cash_value_adj / 100;
    }
    return offer;
  }, [settings, adjValue, overstockPreview]);

  if (settings) {
    return (
      <Paper sx={{ p: 2, mb: 8 }}>
        <Grid container alignItems="top" spacing={1}>
          {infoBar &&
            <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
          }
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={1.5}>
              <Grid item xs={12} sx={{ p: 1, mb: 2 }}>
                <Typography variant="body2">
                  Each percentage setting will modify the value of either the retail value or suggested offer. Amounts over 100% will increase the value and/or offer. Each modifier
                  acts independently as a <em>discount</em>. For example, for a $100 minifigure, a used modifer of 90% will lower the retail value to $90, if the minifigure also damaged with a modifier of 90%,
                  will result in a retail value that is lowered to $80.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                    <Tooltip title={`Define the value.`}>
                      <HelpIcon sx={{ fontSize: 14 }} />
                    </Tooltip> Named Minifigures
                  </Grid>
                  <Box width="100%" />
                  <Grid item xs={0.30}>
                    <Tooltip title={`The initial retail value to use for Minifigs. Currently, Bricklink pricing data is updated about once a month`}>
                      <HelpIcon sx={{ fontSize: 14 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>Initial Minifig Retail Value</Grid>
                  <Grid item xs={4}>
                    <DefaultPriceSelector value={settings.default_minifig_price} onSelect={(value) => updateDefaultPrice('default_minifig_price', value)} />
                  </Grid>
                  <Box width="100%" />
                  <Grid item xs={0.30}>
                    <Tooltip title={`The initial retail value to use for CMFs. Currently, Bricklink pricing data is updated about once a month`}>
                      <HelpIcon sx={{ fontSize: 14 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>Initial CMF Retail Value</Grid>
                  <Grid item xs={4}>
                    <DefaultPriceSelector value={settings.default_cmf_price} onSelect={(value) => updateDefaultPrice('default_cmf_price', value)} />
                  </Grid>
                  <Box width="100%" />
                  <Grid item xs={0.30}>
                    <Tooltip title={`The starting credit to offer for "named" minifigures. For example, 40% for a $10 minifig would mean a minimum offer of $4. `}>
                      <HelpIcon sx={{ fontSize: 14 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>Starting percentage to offer</Grid>
                  <Grid item xs={2}>
                    <PercentageInput
                      label="Credit"
                      fullWidth
                      value={settings.minifig_credit_minimum_adj}
                      onChange={(value) => updateSetting('minifig_credit_minimum_adj', value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <PercentageInput
                      label="Cash"
                      fullWidth
                      value={settings.minifig_cash_minimum_adj}
                      onChange={(value) => updateSetting('minifig_cash_minimum_adj', value)}
                    />
                  </Grid>
                  <Box width="100%" />
                  <Grid item xs={0.30}>
                    <Tooltip title={`The maximum percentage you are willing to offer for a "named" minifigure. For example, 20% for a $10 minifig would mean a maximum offer of $2.`}>
                      <HelpIcon sx={{ fontSize: 14 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>Max percentage to offer</Grid>
                  <Grid item xs={2}>
                    <PercentageInput
                      label="Credit"
                      fullWidth
                      value={settings.minifig_credit_value_adj}
                      onChange={(value) => updateSetting('minifig_credit_value_adj', value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <PercentageInput
                      label="Cash"
                      fullWidth
                      value={settings.minifig_cash_value_adj}
                      onChange={(value) => updateSetting('minifig_cash_value_adj', value)}
                    />
                  </Grid>
                  <Box width="100%" />
                </Grid>
              </Grid>
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Tooltip title={`You can modify these bands to fit how your store values Minifigs. For each band, you can provide a name, it's resale value, and the default maximum credit and cash you are willing to offer.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip> Pre-Defined Price Bands
              </Grid>
              <Box width="100%" />
              {minifigPriceBands.map((priceBand) =>
                <MinifigPriceBandEntry priceBand={priceBand} updatePriceBand={(prop, value) => updatePriceBand(priceBand.id, prop, value)} />
              )}
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The starting credit offered for generic Minifigs, for example, 40% for a $5 minifigure would mean the $2. `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Starting percentage to offer</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Credit"
                  fullWidth
                  value={settings.minifig_generic_credit_minimum_adj}
                  onChange={(value) => updateSetting('minifig_generic_credit_minimum_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Cash"
                  fullWidth
                  value={settings.minifig_generic_cash_minimum_adj}
                  onChange={(value) => updateSetting('minifig_generic_cash_minimum_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The maximum percentage you are willing to offer for a generic minifigure. For example, 20% for a $10 minifig would mean a maximum offer of $2.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Max percentage to offer</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Credit"
                  fullWidth
                  value={settings.minifig_generic_credit_value_adj}
                  onChange={(value) => updateSetting('minifig_generic_credit_value_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Cash"
                  fullWidth
                  value={settings.minifig_generic_cash_value_adj}
                  onChange={(value) => updateSetting('minifig_generic_cash_value_adj', value)}
                />
              </Grid>
              <Box width="100%" />

              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Typography variant="body2">
                  The following modifiers will lower the retail value of the minifig.
                </Typography>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Use this to adjust how much you are willing to offer off of retail value for minifigures that are in poor condition, for example, some light scuff marks or loose arms would qualify as a minifig in poor condition.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Used</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.minifig_used_adj}
                  onChange={(value) => updateSetting('minifig_used_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Use this to adjust the maximum you are willing to offer for minifigures that are damaged, for example, a cracked torso would qualify as a minifig that is damaged.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Damaged</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.minifig_damaged_adj}
                  onChange={(value) => updateSetting('minifig_damaged_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Use this to adjust the maximum you are willing to offer for minifigures that are incomplete, this applies mostly to CMF figures that is missing an accessory (eg: the trans medium blue popsicle for the Yeti from CMF Series 11). `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Incomplete</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.minifig_incomplete_adj}
                  onChange={(value) => updateSetting('minifig_incomplete_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Typography variant="body2">
                  The following modifiers will not modify the retail value, these will only alter the cash/credit offer.
                </Typography>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Use this to adjust the maximum you are willing to offer for minifigures that you have excess stock for, enter 100% if you do not want to discount for overstock.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Overstock</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.minifig_overstock_adj}
                  onChange={(value) => updateSetting('minifig_overstock_adj', value)}
                />
              </Grid>
              <Box width="100%" />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} sx={{ pl: 2 }}>
              <Grid item xs={12} md={12}>
                <Button variant="contained" fullWidth onClick={saveSettings}>Save</Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button fullWidth onClick={resetSettings}>Reset to default settings</Button>
                <ConfirmationDialog show={showResetConfirm} onClose={handleResetConfirm} message="Are you sure you want to reset your settings?" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ ml: 4, fontStyle: 'italic' }}>
                  <h4>Assigned Value Preview:</h4>
                  <p><Checkbox checked={damagedPreview} label="Damaged" onChange={(e) => setDamagedPreview(e.target.checked)} /></p>
                  <p><Checkbox checked={incompletePreview} label="Incomplete" onChange={(e) => setIncompletePreview(e.target.checked)} /></p>
                  <p><Checkbox checked={usedPreview} label="Dirty" onChange={(e) => setUsedPreview(e.target.checked)} /></p>
                  <p><Checkbox checked={overstockPreview} label="Overstock" onChange={(e) => setOverstockPreview(e.target.checked)} /></p>
                  <p>Resale Value: $50</p>
                  <p>Adjusted Resale Value: {formatCurrency(adjValue, false)}</p>
                  <p>Credit: {formatCurrency(offerValues.credit.min, false)}- {formatCurrency(offerValues.credit.max, false)}</p>
                  <p>Cash: {formatCurrency(offerValues.cash.min, false)}- {formatCurrency(offerValues.cash.max, false)}</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper >
    );
  } else {
    return (<></>);
  }
};
import * as React from 'react';
import { Box, Button, Grid, Link, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, Outlet } from 'react-router-dom';
import { SessionContext, TSessionContext } from 'lib';
import { models } from 'model';
import { useLogout } from 'components';
import useStyles from './styles';

/**
 * ReportsMenu component extracted from StoreNav to use in both Admin and Store Navs
 * @constructor
 */
const ReportsMenu: React.FC = () => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const classes = useStyles();
  const [reportsEl, setReportsEl] = React.useState<null | HTMLElement>(null);
  const reportsOpen = Boolean(reportsEl);
  const handleReportsClick = (event: React.MouseEvent<HTMLButtonElement>) => setReportsEl(event.currentTarget);
  const handleReportsClose = () => setReportsEl(null);

  return (
    <>
      <Button
        className="menuItem"
        id="reports-button"
        aria-controls={reportsOpen ? 'reports-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={reportsOpen ? 'true' : undefined}
        onClick={handleReportsClick}
      >
        Reports <ExpandMoreIcon />
      </Button>
      <Menu
        id="reports-menu"
        anchorEl={reportsEl}
        open={reportsOpen}
        onClose={handleReportsClose}
        className={classes.menu}
        MenuListProps={{ 'aria-labelledby': 'reports-button', }}
      >
        <MenuItem component={"a"} href="/reports/buys" onClick={handleReportsClose}>Daily Buys</MenuItem>
        {isAdmin() && <MenuItem component={"a"} href="/reports/units" onClick={handleReportsClose}>Units Report</MenuItem>}
      </Menu>
    </>
  );
}

const AdminNav: React.FC = () => {
  return (
    <>
      <Link href="/units" className="menuItem">Units</Link>
      <Link href="/heartlands" className="menuItem">Heartland Instances</Link>
      <Link href="/buys" className="menuItem">Buys/Trades</Link>
      <Link href="/users" className="menuItem">Users</Link>
      <Link href="/customers" className="menuItem">Customers</Link>
      <Link href="/catalog" className="menuItem">Catalog</Link>
      <ReportsMenu />
    </>
  );
}

const StoreNav: React.FC = () => {
  const { isOwner } = React.useContext(SessionContext) as TSessionContext;
  const classes = useStyles();
  const [settingsEl, setSettingsEl] = React.useState<null | HTMLElement>(null);
  const settingsOpen = Boolean(settingsEl);
  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => setSettingsEl(event.currentTarget);
  const handleSettingsClose = () => setSettingsEl(null);

  return (
    <>
      <Button href="/customers" className="menuItem">Customers</Button>
      <Button href="/buys" className="menuItem">Buys/Trades</Button>
      <Button href="/buys/new" className="menuItem">Buy Calculator</Button>
      <Button href="/catalog" className="menuItem">Catalog</Button>
      {isOwner() &&
        <>
          <ReportsMenu />
          <Button
            className="menuItem"
            id="settings-button"
            aria-controls={settingsOpen ? 'settings-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={settingsOpen ? 'true' : undefined}
            onClick={handleSettingsClick}
          >
            Settings <ExpandMoreIcon />
          </Button>
          <Menu
            id="settings-menu"
            anchorEl={settingsEl}
            open={settingsOpen}
            onClose={handleSettingsClose}
            className={classes.menu}
            MenuListProps={{ 'aria-labelledby': 'settings-button', }}
          >
            <MenuItem component={"a"} href="/buy_settings/minifigs" onClick={handleSettingsClose}> Buy Calculator</MenuItem>
            <MenuItem component={"a"} href="/inventory_settings" onClick={handleSettingsClose}> Inventory</MenuItem>
            <MenuItem component={"a"} href="/rewards_settings" onClick={handleSettingsClose}> Rewards Signup</MenuItem>
            <MenuItem component={"a"} href="/users" onClick={handleSettingsClose}>Users</MenuItem>
            <MenuItem component={"a"} href="/units" onClick={handleSettingsClose}>Locations</MenuItem>
          </Menu>
        </>
      }
    </>
  );
}

const NavSwitcher: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;

  if (currentUser) {
    if (models.user.isAdmin(currentUser)) {
      return (<AdminNav />);
    } else {
      return (<StoreNav />);
    }
  } else {
    return (<></>);
  }
};

type Props = {
  children?: React.ReactNode
};


export const Navigation: React.FC<Props> = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const navigate = useNavigate();
  const { logout } = useLogout();
  const classes = useStyles();

  const doLogout = () => {
    logout();
    navigate('/login');
  };

  const doAccount = () => {
    navigate('/user/account');
  };

  return (
    <>
      <header className={classes.header}>
        <Link href="/"><img src="/logo_straight.png" width={120} alt="Bricks & Minifigs" /></Link>
        <NavSwitcher />
        <div className="menuActions">
          <Grid container alignContent={"center"} justifyContent={"center"}>
            {currentUser &&
              <Grid item>
                <Box sx={{ width: "100%", p: 2 }}>
                  <Link component="button" className="menuItem" onClick={doAccount}>Account</Link>
                  <Link component="button" className="menuItem" onClick={doLogout}>Logout</Link>
                </Box>
              </Grid>
            }
          </Grid>
        </div>
      </header >
      <div className="content">
        <Outlet />
      </div>
    </>
  );
}
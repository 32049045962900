import * as React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from '@mui/material';
import { formatCurrency } from "lib";

export const BuyColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`/buys/${params.row.id}`}>
          {params.row.id}
        </Link>
      </>
    )
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 180,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.unit.name}
      </>
    )
  },
  {
    field: 'customer',
    headerName: 'Customer',
    width: 140,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.customer &&
          < Link href={`customers/${params.row.customer.id}`}>
            {params.row.customer.first_name} {params.row.customer.last_name?.charAt(0).toUpperCase()}
          </Link>
        }
      </>
    )
  },
  {
    field: 'creator',
    headerName: 'Employee',
    width: 140,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`users/${params.row.creator.id}`}>
          {params.row.creator.first_name} {params.row.creator.last_name.charAt(0).toUpperCase()}
        </Link>
      </>
    )
  },
  {
    field: 'summary',
    headerName: 'Summary',
    width: 420,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 90,
  },
  {
    field: 'buy_type',
    headerName: 'Type',
    type: 'number',
    width: 90,
  },
  {
    field: 'amount_paid',
    headerName: 'Amount Paid',
    headerAlign: 'right',
    align: 'right',
    width: 120,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.buy_type === "trade" ? formatCurrency(params.row.credit_paid) : formatCurrency(params.row.cash_paid)}
      </>
    )
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    headerAlign: 'right',
    align: 'right',
    type: 'date',
    width: 90,
    valueGetter: (params) => params.value && new Date(params.value),
  },
];
import * as React from 'react';
import { Box, Button, Chip, Grid, Skeleton, TextField, Typography } from '@mui/material';
import { SessionContext, TSessionContext, api } from 'lib';
import { useAddLegoSetImage } from 'api';
import { CopyToClipboardButton, LegoSetExternalLinks, LegoSetRebrickableImage } from 'components';
import { Checkbox } from 'components';
import Carousel from 'react-material-ui-carousel';
import { LegoSetContext, TLegoSetContext } from './context';

export const Details: React.FC = () => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const { legoSet, update, save, addImage } = React.useContext(LegoSetContext) as TLegoSetContext;
  const { addLegoSetImage } = useAddLegoSetImage();
  const [url, setUrl] = React.useState<string>("");

  React.useEffect(() => {
    if (addLegoSetImage.status === api.success && addLegoSetImage.image) {
      addImage(addLegoSetImage.image);
      addLegoSetImage.reset();
      setUrl("");
    }
  }, [addLegoSetImage, legoSet]);

  const doAdd = () => {
    if (legoSet) {
      addLegoSetImage.add(url, legoSet.id, 'new');
    }
  };

  const updateUrl = ((e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value.split('?')[0]);
  });

  return (
    <Grid container spacing={2} sx={{ mb: 8 }}>
      <Grid item xs={4}>
        {legoSet.new_images.length > 0 ?
          <Carousel autoPlay={false}>
            {legoSet.new_images.map((image) => <Box sx={{ width: '100%', height: 400, textAlign: 'center', mx: 'auto' }} key={image.id}>
              <span style={{ display: 'inline-block', height: '100%', verticalAlign: 'middle' }}></span>
              <img src={image.image_url} alt={legoSet.name} style={{ maxWidth: 370, maxHeight: 370, verticalAlign: 'middle' }} />
            </Box>
            )}
          </Carousel> :
          <LegoSetRebrickableImage legoSet={legoSet} />}
      </Grid>
      <Grid item xs={8}>
        {isAdmin() ?
          <Grid container sx={{ mb: 4 }} spacing={2}>
            <Grid item xs={12}>
              <TextField multiline variant="outlined" fullWidth label="Description" value={legoSet.description}
                onChange={(e) => update('description', e.target.value)} />
            </Grid>
            <Grid item xs={5}>
              <TextField variant="outlined" fullWidth label="Release Year" value={legoSet.release_year}
                onChange={(e) => update('release_year', e.target.value)} />
            </Grid>
            <Grid item xs={5}>
              <TextField variant="outlined" fullWidth label="Retirement Year" value={legoSet.retirement_year}
                onChange={(e) => update('retirement_year', e.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={legoSet.retired}
                label="Retired"
                onChange={(e) => update('retired', e.target.checked)} />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined" fullWidth label="Number of Parts" value={legoSet.num_parts}
                onChange={(e) => update('num_parts', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined" fullWidth label="Number of Minifigs" value={legoSet.num_minifigs}
                onChange={(e) => update('num_minifigs', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined" fullWidth label="Bricklink ID" value={legoSet.bricklink_id}
                onChange={(e) => update('bricklink_id', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined" fullWidth label="Brickset ID" value={legoSet.brickset_id}
                onChange={(e) => update('brickset_id', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined" fullWidth label="Rebrickable ID" value={legoSet.rebrickable_id}
                onChange={(e) => update('rebrickable_id', e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined" fullWidth label="BrickOwl ID" value={legoSet.brickowl_id}
                onChange={(e) => update('brickowl_id', e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right' }}>
                <Button variant="contained" onClick={save}>Save</Button>
              </Box>
            </Grid>
          </Grid>
          :
          <>
            <Chip color="primary" variant="outlined" label={`${legoSet.category}`} sx={{ mr: 1 }} />
            <Chip color="info" variant="outlined" label={`${legoSet.num_parts} Parts `} sx={{ mr: 1 }} />
            <Chip color="info" variant="outlined" label={`${legoSet.num_minifigs} Minifigs`} sx={{ mr: 1 }} />
            <Chip color="info" variant="outlined" label={`Released: ${legoSet.release_year}`} sx={{ mr: 1 }} />
            {legoSet.retired &&
              <Chip color="warning" label={`Retired: ${legoSet.retirement_year}`} sx={{ mx: 1 }} />}
            <Box sx={{ my: 4 }}>
              <Typography variant="body1" sx={{ mb: 4 }}> {legoSet.description} </Typography>
            </Box>
            <LegoSetExternalLinks legoSet={legoSet} />
          </>}
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Images
        </Typography>
        <Grid container spacing={6} sx={{ mb: 2 }}>
          {legoSet.new_images.map((i) => <Grid item key={i.id} xs="auto">
            <Grid container spacing={0} alignItems="center" justifyContent="center">
              <Grid item xs="auto">
                <img alt={i.image_url} src={i.image_url} style={{ maxWidth: 64, maxHeight: 64 }} />
              </Grid>
              <Grid item xs="auto">
                <CopyToClipboardButton content={i.image_url} />
              </Grid>
            </Grid>
          </Grid>
          )}
          {addLegoSetImage.status === api.loading &&
            <Grid item>
              <Skeleton variant="rectangular" width={64} height={64} animation="wave" />
            </Grid>}
        </Grid>
        {isAdmin() &&
          <Grid container spacing={2} sx={{ mt: 2 }} alignItems="center">
            <Grid item xs={8}>
              <TextField fullWidth variant="outlined" size="small" label="Add a new image" value={url} onChange={updateUrl} />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" onClick={doAdd}>Add</Button>
            </Grid>
          </Grid>}
      </Grid>
    </Grid>
  );
};

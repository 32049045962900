import * as React from 'react';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { LegoSetModel } from 'model';
import { EbaySetLink, TCell } from 'components';

interface Props {
  legoSet: LegoSetModel;
}


export const LegoSetBricklinkPrices: React.FC<Props> = ({ legoSet }: Props) => {

  const formatPrice = (val: any): string => val ? '$' + Number(val).toFixed(2) : 'N/A';

  function findMedian(arr: number[]) {
    arr.sort((a, b) => a - b);
    const middleIndex = Math.floor(arr.length / 2);

    if (arr.length % 2 === 0) {
      return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
    } else {
      return arr[middleIndex];
    }
  }

  const newBAM = React.useMemo(() => {
    let sum = 0;
    let min = 999997;
    let max = 0;
    let count = 0;
    let paid: number[] = [];
    legoSet.recent_nib_buys.forEach((line) => {
      sum += +line.cost;
      max = line.cost > max ? line.cost : max;
      min = line.cost < min ? line.cost : min;
      count++;
      paid.push(+line.cost);
    });
    if (count === 0) {
      return {
        avg: 0,
        min: 0,
        max: 0,
        med: 0,
      }
    }
    return {
      avg: sum / count,
      min: min,
      max: max,
      med: findMedian(paid),
    }
  }, [legoSet]);


  const usedBAM = React.useMemo(() => {
    let sum = 0;
    let min = 999997;
    let max = 0;
    let count = 0;
    let paid: number[] = [];
    legoSet.recent_used_buys.forEach((line) => {
      sum += +line.cost;
      max = +line.cost > max ? +line.cost : max;
      min = +line.cost < min ? +line.cost : min;
      count++;
      paid.push(+line.cost);
    });
    if (count === 0) {
      return {
        avg: 0,
        min: 0,
        max: 0,
        med: 0,
      }
    }
    return {
      avg: sum / count,
      min: min,
      max: max,
      med: findMedian(paid),
    }
  }, [legoSet]);

  return (
    <TableContainer component={Paper} sx={{ mb: 2 }}>
      <Table aria-label="Price Guide" size="small">
        <TableHead>
          <TableRow>
            <TCell />
            <TCell align='center' colSpan={2}><strong>eBay (Sold))</strong></TCell>
            <TCell align='center' colSpan={2}><strong>Bricklink (Sold)</strong></TCell>
            <TCell align='center' colSpan={2}><strong>BAM (Cost)</strong></TCell>
          </TableRow>
          <TableRow>
            <TCell align='right'></TCell>
            <TCell align='right'><strong>New</strong></TCell>
            <TCell align='right'><strong>Used</strong></TCell>
            <TCell align='right'><strong>New</strong></TCell>
            <TCell align='right'><strong>Used</strong></TCell>
            <TCell align='right'><strong>New</strong></TCell>
            <TCell align='right'><strong>Used</strong></TCell>
          </TableRow>
        </TableHead>
        <TableBody>


          <TableRow>
            <TCell align='left'>Avg</TCell>
            <TCell align='right'><EbaySetLink item={legoSet} text="New" condition="new" /></TCell>
            <TCell align='right'><EbaySetLink item={legoSet} text="Used" condition="used" /></TCell>
            <TCell align='right'>{formatPrice(legoSet.new_bricklink_prices.avg)}</TCell>
            <TCell align='right'>{formatPrice(legoSet.used_bricklink_prices.avg)}</TCell>
            <TCell align='right'>{formatPrice(newBAM.avg)}</TCell>
            <TCell align='right'>{formatPrice(usedBAM.avg)}</TCell>
          </TableRow>

          <TableRow>
            <TCell align='left'>Min</TCell>
            <TCell></TCell>
            <TCell></TCell>
            <TCell align='right'>{formatPrice(legoSet.new_bricklink_prices.min)}</TCell>
            <TCell align='right'>{formatPrice(legoSet.used_bricklink_prices.min)}</TCell>
            <TCell align='right'>{formatPrice(newBAM.min)}</TCell>
            <TCell align='right'>{formatPrice(usedBAM.min)}</TCell>
          </TableRow>

          <TableRow>
            <TCell align='left'>Max</TCell>
            <TCell></TCell>
            <TCell></TCell>
            <TCell align='right'>{formatPrice(legoSet.new_bricklink_prices.max)}</TCell>
            <TCell align='right'>{formatPrice(legoSet.used_bricklink_prices.max)}</TCell>
            <TCell align='right'>{formatPrice(newBAM.max)}</TCell>
            <TCell align='right'>{formatPrice(usedBAM.max)}</TCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
import React, { useContext, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { useUpdatePassword } from "../login/api/savepassword";
import { formRowStyle as rowStyle, formDescriptionStyle as descriptionStyle } from "./styles";
import { FlashMessage } from "../../components";
import { useNavigate } from "react-router-dom";
import { SessionContext, TSessionContext } from "../../lib";

export const UpdatePasswordForm: React.FC = () => {
    const navigate = useNavigate();
    const { setFlashMessage } = useContext(SessionContext) as TSessionContext;
    const {
        currentPassword,
        password,
        confirmPassword,
        updateCurrentPassword,
        updatePassword,
        updateConfirmPassword,
        passwordsMatch,
        validPassword,
        disable,
        passwordError,
        saveSuccess,
        saveError,
        message,
        doUpdatePassword,
    } = useUpdatePassword();

    /**
     * Set the flash message and after a delay navigate to login
     */
    useEffect(() => {
        if (saveSuccess) {
            setFlashMessage({
                message: "Password updated successfully! Please log in again.",
                status: 'success',
            });
            const timer = setTimeout(() => {
                navigate("/login");
            }, 1000);
            return () => {
                clearTimeout(timer);
            }
        }
    }, [saveSuccess, setFlashMessage, navigate]);

    return (
        <Box sx={rowStyle}>
            <Box sx={descriptionStyle}>
                Password must contain at least 7 characters and include at least 1 lower-case, 1 upper-case and 1
                numeric character.
            </Box>
            <Box sx={rowStyle}>
                <TextField
                    id="currentPassword"
                    label="Current Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={currentPassword}
                    onChange={updateCurrentPassword}
                />
            </Box>
            <Box sx={rowStyle}>
                <TextField
                    id="password"
                    label="New Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={updatePassword}
                />
            </Box>
            <Box sx={rowStyle}>
                <TextField
                    id="confirmPassword"
                    label="Confirm New Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={updateConfirmPassword}
                />
            </Box>
            <Box sx={rowStyle}>
                {!validPassword && <div className="error">{passwordError}</div>}
                {!passwordsMatch && <div className="error">Passwords do not match!</div>}
                {saveError && message && <div className="error">{message} Please try again.</div>}
                {saveSuccess && <div className="success">Password updated successfully! You will be logged out now. Please log in again.</div>}
            </Box>
            <Box sx={rowStyle}>
                <Button variant="contained" onClick={doUpdatePassword} disabled={disable}>UPDATE PASSWORD</Button>
            </Box>

        </Box>
    )
}
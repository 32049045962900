import * as React from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

import { BuyModel } from 'model';
import { Button } from '@mui/material';
import { useGetInventorySettings } from 'api';
import { SessionContext, TSessionContext, api } from 'lib';

const header = ["PO #",
  "PO Description",
  "PO Start Ship",
  "PO End Ship",
  "PO Vendor",
  "PO Received at location",
  "Item Description",
  "Item Current Price",
  "Item Active?",
  "Item Track Inventory?",
  "Item Primary Vendor",
  "Item Taxable",
  "Item Department",
  "Item Category",
  "Item Series",
  "Item #",
  "PO Line Unit Cost",
  "PO Line Qty",
  "Item bricklink_id",
];

export interface ImportList {
  new_sets: boolean;
  used_sets: boolean;
  minifigs: boolean;
  cmf: boolean;
}

interface Props {
  buy: BuyModel;
  importList: ImportList;
}

export const PurchaseOrderImpot: React.FC<Props> = ({ buy, importList }: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { getInventorySettings } = useGetInventorySettings();
  const csvLink = React.useRef<CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }>(null);

  React.useEffect(() => {
    if (currentHeartland) {
      getInventorySettings.get(currentHeartland.id);
    }
  }, [currentHeartland]);

  console.log(importList);

  const poVendor = React.useMemo(() => {
    if (getInventorySettings.status === api.success && getInventorySettings.inventorySetting) {
      return getInventorySettings.inventorySetting.buy_trade_vendor_name;
    }
    return "Buy/Trade";
  }, [getInventorySettings.status, getInventorySettings.inventorySetting]);

  const itemNumber = (itemNumber: string, condition: string, retired: boolean) => {
    switch (condition) {
      case 'used':
        return `${itemNumber}${getInventorySettings.inventorySetting?.complete_set_postfix}`;
      case 'certifed':
        return `${itemNumber}${getInventorySettings.inventorySetting?.certified_set_postfix}`;
      case 'opened':
        return `${itemNumber}${getInventorySettings.inventorySetting?.open_box_postfix}`;
      case 'build_only':
        return `${itemNumber}${getInventorySettings.inventorySetting?.build_only_set_postfix}`;
      case 'project':
        return `${itemNumber}${getInventorySettings.inventorySetting?.project_set_postfix}`;
      default:
        if (retired && getInventorySettings.inventorySetting?.auto_set_retired) {
          return `${itemNumber}${getInventorySettings.inventorySetting?.retired_set_postfix}`;
        }
        return itemNumber;
    }
  };

  const importData = React.useMemo(() => {
    const paid = buy.buy_type === "cash" ? buy.cash_paid : buy.credit_paid;
    const poNumber = `PATRON-${buy.id}`;
    const poDescription = buy.summary;
    const poStartShip = moment(buy.updated_at).format("L");
    const poEndShip = moment(buy.updated_at).format("L");
    const poLocation = buy.unit.heartland_name || buy.unit.name;
    const csvData = [];
    csvData.push(header);
    if (importList.new_sets) {
      buy.new_set_buy_lines.forEach((line) => {
        csvData.push([
          poNumber,
          `"${poDescription}"`,
          poStartShip,
          poEndShip,
          `"${poVendor}"`,
          `"${poLocation}"`,
          `"${line.lego_set.set_number} ${line.lego_set.name}"`,
          `${line.value}`,
          "yes",
          "yes",
          `"${poVendor}"`,
          "yes",
          "New Sets",
          `"${line.lego_set.category}"`,
          "",
          itemNumber(line.lego_set.set_number, line.opened ? 'opened' : 'new', line.lego_set.retired),
          `${line.cost_final}`,
          `${line.quantity}`,
          line.lego_set.bricklink_id
        ]);
      });
    }
    if (importList.used_sets) {
      buy.used_set_buy_lines.forEach((line) => {
        csvData.push([
          poNumber,
          `"${poDescription}"`,
          poStartShip,
          poEndShip,
          `"${poVendor}"`,
          `"${poLocation}"`,
          `"${line.lego_set.set_number} ${line.lego_set.name}"`,
          `${line.value}`,
          "yes",
          "yes",
          `"${poVendor}"`,
          "yes",
          "Used Sets",
          `"${line.lego_set.category}"`,
          "",
          itemNumber(line.lego_set.set_number, 'used', false),
          `${line.cost_final}`,
          `${line.quantity}`,
          line.lego_set.bricklink_id
        ]);
      });
    }
    if (importList.cmf) {
      buy.series_minifig_buy_lines.forEach((line) => {
        csvData.push([
          poNumber,
          `"${poDescription}"`,
          poStartShip,
          poEndShip,
          `"${poVendor}"`,
          poLocation,
          `"CMF ${line.series_minifig.series} - ${line.series_minifig.name}"`,
          `${line.value}`,
          "yes",
          "yes",
          `"${poVendor}"`,
          "yes",
          "Minifigs",
          "CMF",
          `"${line.series_minifig.series}"`,
          line.series_minifig.bricklink_id,
          `${line.cost_final}`,
          `${line.quantity}`,
          line.series_minifig.bricklink_id
        ]);
      });
    }
    if (importList.minifigs) {
      buy.minifig_buy_lines.forEach((line) => {
        if (line.minifig) {
          csvData.push([
            poNumber,
            `"${poDescription}"`,
            poStartShip,
            poEndShip,
            `"${poVendor}"`,
            poLocation,
            `"${line.minifig.bricklink_id} - ${line.minifig.name}"`,
            `${line.value}`,
            "yes",
            "yes",
            `"${poVendor}"`,
            "yes",
            "Minifigs",
            "",
            "",
            line.minifig.bricklink_id,
            `${line.cost_final}`,
            `${line.quantity}`,
            line.minifig.bricklink_id
          ]);
        }
      });
    }
    return csvData;
  }, [buy, getInventorySettings.inventorySetting, importList]);

  const doDownload = () => {
    csvLink?.current?.link?.click();
  };

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<DownloadIcon />} onClick={doDownload}>Heartland PO CSV Import</Button>
      <CSVLink ref={csvLink} data={importData} enclosingCharacter="" filename={`patron_${buy.id}.csv`} target="_blank" />
    </>
  );

};
import * as React from 'react';
import { Grid, TextField, Box, Button } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import LaunchIcon from '@mui/icons-material/Launch';

import { InfoBar, UserSelector } from 'components';
import { api } from 'lib';
import { HeartlandModel, InfoBarModel, UnitModel, UserModel } from 'model';
import { useUpdateHeartland } from '../../../api/heartland';

interface ApiKeyFielProps {
  apiKey: string;
  onSave: (apiKey: string) => void;
}

const ApiKeyField: React.FC<ApiKeyFielProps> = ({ apiKey, onSave }: ApiKeyFielProps) => {
  const [newApiKey, setNewApiKey] = React.useState<string>("");
  const [showEdit, setShowEdit] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShowEdit(false);
    setNewApiKey('');
  }, [apiKey]);

  const doSave = () => {
    onSave(newApiKey)
  };

  if (showEdit) {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            id="apiKey"
            label="API Key"
            variant="outlined"
            fullWidth
            value={newApiKey}
            onChange={(e) => setNewApiKey(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <Button
            variant="contained"
            aria-label="Save"
            startIcon={<SaveIcon />}
            onClick={doSave}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={12} md="auto">
          <Button
            variant="contained"
            aria-label="Cancel"
            startIcon={<CancelIcon />}
            onClick={() => setShowEdit(false)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
        {apiKey} <Button onClick={() => setShowEdit(true)}>Edit</Button>
      </>
    );
  }
};

interface UnitListProps {
  units: UnitModel[];
}

const UnitList: React.FC<UnitListProps> = ({ units }: UnitListProps) => {
  return (
    <Grid container spacing={2}>
      {units.map((unit) =>
        <Grid item xs={12} key={unit.id}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Link to={`/units/${unit.id}`}>{unit.name}</Link>
            </Grid>
            <Grid item xs={12}>
              {unit.city}, {unit.state}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

interface FormProps {
  heartland: HeartlandModel;
}

export const HeartlandSettingsTab: React.FC<FormProps> = (props: FormProps) => {
  const { state } = useLocation();
  const { status, error, heartland: updatedHeartland, update, updateApiKey } = useUpdateHeartland();
  const [heartland, setHeartland] = React.useState<HeartlandModel>(props.heartland);
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);

  const updateHeartland = (property: string, value: string | UserModel) => setHeartland({ ...heartland, [property]: value });
  const doUpdate = () => update(heartland);
  const reset = () => setHeartland(props.heartland);
  const saveApiKey = (apiKey: string) => updateApiKey(heartland.id, apiKey);
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (state && state.message) {
      setInfoBar({ status: state.type, message: state.message });
    } else if (status === api.success && updatedHeartland) {
      setHeartland(updatedHeartland);
      setInfoBar({ status: "success", message: 'successfully saved.' });
    } else if (status === api.error) {
      setInfoBar({ status: "error", message: error });
    } else {
      setInfoBar(null);
    }
  }, [state, status, error, setInfoBar, setHeartland, updatedHeartland]);

  const heartlandUrl = React.useMemo(() => {
    return `https://${heartland.domain}.retail.heartland.us`;
  }, [heartland.domain]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        {infoBar &&
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        }
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              id="domain"
              label="Domain"
              variant="outlined"
              fullWidth value={heartland.domain}
              onChange={(e) => updateHeartland('domain', e.target.value)} />
          </Grid>
          <Grid item xs={6}>
            <Link to={heartlandUrl} className="externalLink">
              <Box
                display="flex"
                alignItems="center"
              >
                <span>{heartlandUrl}</span>
                <LaunchIcon />
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <UserSelector value={heartland.user} onSelect={(value) => updateHeartland('user', value)} />
          </Grid>
          <Grid item xs={12} md={12}>
            <h4> Heartland API Key </h4>
            <ApiKeyField apiKey={heartland.api_key} onSave={saveApiKey} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <h4>Locations / Units</h4>
          {heartland.units && <UnitList units={heartland.units} />}
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Save Edits"
              startIcon={<SaveIcon />}
              onClick={doUpdate}
              fullWidth
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Reset"
              startIcon={<RestorePageIcon />}
              onClick={reset}
              fullWidth
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="Cancel"
              startIcon={<CancelIcon />}
              href="/heartlands"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
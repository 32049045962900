import * as React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Link, TextField, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import LaunchIcon from '@mui/icons-material/Launch';

import { HeartlandCustomFieldModel, RewardsSignupFormModel } from 'model';
import { HeartlandCustomFieldSelector } from 'components/heartland_custom_field_selector';
import { CustomField } from './custom_field';

interface FormProps {
  isAdmin?: boolean;
  rewardsSignupForm?: RewardsSignupFormModel;
  customFields?: HeartlandCustomFieldModel[];
  onUpdate?: (rewardsSignupForm: RewardsSignupFormModel, valid: boolean) => void;
}

const defaultRewardsSignupForm = {
  id: 0,
  heartland_id: 0,
  short_code: '',
  header: '',
  message: '',
  confirmation_message: '',
  email_marketing: false,
  emailmarketing_customfield: '',
  birth_month: false,
  birthmonth_customfield: '',
  source: false,
  source_customfield: '',
  default_customfield_values: {},
};

export const RewardsSignupForm: React.FC<FormProps> = (props: FormProps) => {
  const [rewardsSignupForm, setRewardsSignupForm] = React.useState<RewardsSignupFormModel>(props.rewardsSignupForm ? props.rewardsSignupForm : defaultRewardsSignupForm);

  const updateRewardsSignupForm = (property: string, value: string | boolean) => setRewardsSignupForm({ ...rewardsSignupForm, [property]: value });

  const customFields = React.useMemo(() => props.customFields || [], [props.customFields]);

  const rewardsPageUrl = React.useMemo(() => {
    return rewardsSignupForm ? `https://patron.bricksandminifigs.com/rewards/${rewardsSignupForm.short_code}` : '';
  }, [rewardsSignupForm]);

  const updateCustomField = (key: string, value: string) => {
    const updatedValues = { ...rewardsSignupForm.default_customfield_values };
    updatedValues[key] = value;
    const newFormData = ({ ...rewardsSignupForm, default_customfield_values: updatedValues });
    setRewardsSignupForm(newFormData);
  };

  const getMappedValue = React.useCallback((field: HeartlandCustomFieldModel): string => {
    if (rewardsSignupForm.default_customfield_values && field.key in rewardsSignupForm.default_customfield_values) {
      return rewardsSignupForm.default_customfield_values[field.key];
    }
    return '';
  }, [rewardsSignupForm]);

  const getMapped = React.useCallback((field: HeartlandCustomFieldModel): string | null => {
    if (rewardsSignupForm.birthmonth_customfield === field.uuid) {
      return "Birth Month";
    } else if (rewardsSignupForm.emailmarketing_customfield === field.uuid) {
      return "Email Marketing";
    } else if (rewardsSignupForm.source_customfield === field.uuid) {
      return "How did you hear about us?";
    } else {
      return null;
    }
  }, [rewardsSignupForm]);

  React.useEffect(() => {
    const checkRequiredFields = (): boolean => {
      return customFields.every((field) => {
        if (getMapped(field)) {
          return true;
        }
        if (!rewardsSignupForm.default_customfield_values) {
          return false;
        }
        const value = rewardsSignupForm.default_customfield_values[field.key];
        let x = true;
        if (field.required) {
          x = value !== undefined && value !== null;
        }
        return x;
      });
    };
    if (props.onUpdate) {
      props.onUpdate(rewardsSignupForm, checkRequiredFields());
    }
  }, [props, getMapped, rewardsSignupForm]);

  return (
    <Grid container spacing={2} alignItems="center">
      {props.isAdmin &&
        <>
          <Grid item xs={11} md={3}>
            <TextField
              id="short_code"
              label="Short Code"
              variant="outlined"
              fullWidth
              value={rewardsSignupForm.short_code}
              onChange={(e) => updateRewardsSignupForm('short_code', e.target.value)}
            />
          </Grid>
          <Grid item xs={1} md={1}>
            <Tooltip title="Each Rewards Program requires a unique URL, use at least a 6 character long code eg: orem-ut.">
              <HelpIcon />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={8}>
            <Link href={rewardsPageUrl} className="externalLink">
              <Box
                display="flex"
                alignItems="center"
              >
                <span>{rewardsPageUrl}</span>
                <LaunchIcon />
              </Box>
            </Link>
          </Grid>
        </>
      }
      <Grid item xs={12} md={12}>
        <TextField
          id="title"
          label="Signup Page Title"
          variant="outlined"
          fullWidth
          value={rewardsSignupForm.header}
          onChange={(e) => updateRewardsSignupForm('header', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          id="message"
          label="Signup Page Message"
          variant="outlined"
          fullWidth
          multiline
          rows={5}
          value={rewardsSignupForm.message}
          onChange={(e) => updateRewardsSignupForm('message', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          id="confirmation_message"
          label="Confirmation Page Message"
          variant="outlined"
          fullWidth
          multiline
          rows={5}
          value={rewardsSignupForm.confirmation_message}
          onChange={(e) => updateRewardsSignupForm('confirmation_message', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={5} textAlign="left">
        <FormControlLabel
          control={
            <Checkbox
              checked={rewardsSignupForm.email_marketing}
              onChange={(e) => updateRewardsSignupForm('email_marketing', e.target.checked)}
            />}
          label="Enable Email Marketing"
        />
      </Grid>
      <Grid item xs={11} md={6} textAlign="left">
        <HeartlandCustomFieldSelector
          label="Heartland Custom Field"
          fields={customFields}
          value={rewardsSignupForm.emailmarketing_customfield}
          onChange={(v) => updateRewardsSignupForm('emailmarketing_customfield', v)}
        />
      </Grid>
      <Grid item xs={1} md={1}>
        <Tooltip title="The name of the custom field in Heartland you are using to track whether a customer has opted in to email marketing">
          <HelpIcon />
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={5} textAlign="left">
        <FormControlLabel
          control={
            <Checkbox
              checked={rewardsSignupForm.birth_month}
              onChange={(e) => updateRewardsSignupForm('birth_month', e.target.checked)}
            />}
          label="Enable Birthday Month"
        />
      </Grid>
      <Grid item xs={11} md={6} textAlign="left">
        <HeartlandCustomFieldSelector
          label="Heartland Custom Field"
          fields={customFields}
          value={rewardsSignupForm.birthmonth_customfield}
          onChange={(v) => updateRewardsSignupForm('birthmonth_customfield', v)}
        />
      </Grid>
      <Grid item xs={1} md={1}>
        <Tooltip title="The name of the custom field in Heartland you are using to track Birthday Month">
          <HelpIcon />
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={5} textAlign="left">
        <FormControlLabel
          control={
            <Checkbox
              checked={rewardsSignupForm.source}
              onChange={(e) => updateRewardsSignupForm('source', e.target.checked)}
            />}
          label="Enable How did you hear about us"
        />
      </Grid>
      <Grid item xs={11} md={6} textAlign="left">
        <HeartlandCustomFieldSelector
          label="Heartland Custom Field"
          fields={customFields}
          value={rewardsSignupForm.source_customfield}
          onChange={(v) => updateRewardsSignupForm('source_customfield', v)}
        />
      </Grid>
      <Grid item xs={1} md={1}>
        <Tooltip title="The name of the custom field in Heartland you are using to track how the customer heard about">
          <HelpIcon />
        </Tooltip>
      </Grid>
      <Grid item xs="auto">
        <h4>Default Values for Heartland Customer Custom Fields</h4>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="This allows you to pre-populate any of your Customer Custom Fields in Heartland when a customer signs up for the rewards program. NOTE: Required fields must have a value in order for the signup form to work">
          <HelpIcon />
        </Tooltip>
      </Grid>
      {customFields.map((field) =>
        <Grid key={field.key} item xs={12}>
          <CustomField field={field} mapped={getMapped(field)} value={getMappedValue(field)} onUpdate={updateCustomField} />
        </Grid>
      )}
    </Grid>
  );
};

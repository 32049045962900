import * as React from 'react';
import { Chip, Link, Typography } from '@mui/material';
import { LegoSetModel } from 'model';

interface Props {
  legoSet: LegoSetModel;
  detailView?: boolean;
}

export const LegoSetHeading: React.FC<Props> = ({ legoSet, detailView = false }: Props) => {

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {legoSet.set_number} {legoSet.name}
      </Typography>
      <Chip color="primary" variant="outlined" label={`${legoSet.category}`} sx={{ mx: 1 }} />
      <Chip color="info" variant="outlined" label={`${legoSet.num_parts} Parts `} sx={{ mr: 1 }} />
      <Chip color="info" variant="outlined" label={`${legoSet.num_minifigs} Minifigs`} sx={{ mr: 1 }} />
      <Chip color="info" variant="outlined" label={`Released: ${legoSet.release_year}`} sx={{ mr: 1 }} />
      {
        legoSet.retired &&
        <Chip color="warning" label={`Retired ${legoSet.retirement_year ? legoSet.retirement_year : ""}`} sx={{ mx: 1 }} />
      }
      {!detailView && <> &raquo; <Link href={`/sets/${legoSet.bricklink_id}`}>Details</Link></>}
    </>
  );
};
import * as React from 'react';
import { Link, useLocation } from "react-router-dom";

import { Tab, Tabs } from '@mui/material';

export const BuySettingsTabs: React.FC = () => {
  const location = useLocation();

  return (
    <>
      <h2>Buy Calculator Settings</h2>
      <Tabs value={location.pathname} aria-label="Buy Settings Tabs" variant="fullWidth" sx={{ mb: 2 }}>
        <Tab label="General" component={Link} to={"/buy_settings/general"} value="/buy_settings/general" />
        <Tab label="Minifigs & CMFs" component={Link} to={"/buy_settings/minifigs"} value="/buy_settings/minifigs" />
        <Tab label="New Sets" component={Link} to={"/buy_settings/nib"} value="/buy_settings/nib" />
        <Tab label="Used Sets" component={Link} to={"/buy_settings/used"} value="/buy_settings/used" />
        <Tab label="Bulk" component={Link} to={"/buy_settings/bulk"} value="/buy_settings/bulk" />
        <Tab label="Misc" component={Link} to={"/buy_settings/misc"} value="/buy_settings/misc" />
      </Tabs>
    </>
  )
};
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, LinearProgress, Link, Tab, Tabs, Typography } from '@mui/material';
import { api } from 'lib';
import { useGetLegoSet } from 'api';
import { NotFound } from 'components';
import { LegoSetProvider } from './context';
import { a11yProps, MyStoreInfo } from './my_store_info';
import { PriceGuide } from './price_guide';
import { Details } from './details';

export const LegoSetShow: React.FC = () => {
  const { getLegoSetState, getLegoSet } = useGetLegoSet();
  const { bricklink_id } = useParams();

  const [tab, setTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newTab: number) => setTab(newTab);

  React.useEffect(() => {
    if (bricklink_id) {
      getLegoSet(bricklink_id);
    }
  }, [bricklink_id, getLegoSet]);

  if (getLegoSetState.status === api.success && getLegoSetState.legoSet) {
    return (
      <Box sx={{ width: '90%', pt: 2, fontSize: '0.9rem' }}>
        <Box sx={{ mb: 1 }}><Link href="/catalog">Catalog</Link></Box>
        <Typography variant="h4">{getLegoSetState.legoSet.set_number} {getLegoSetState.legoSet.name} </Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
          <Tabs value={tab} onChange={handleChange} aria-label="Heartland Tabs">
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Price Guide" {...a11yProps(1)} />
            <Tab label="@ My Store" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <LegoSetProvider legoSet={getLegoSetState.legoSet}>
          <>
            <Box hidden={tab !== 0} id="tabpanel-0">
              <Details />
            </Box>
            <Box hidden={tab !== 1} id="tabpanel-1">
              <PriceGuide />
            </Box>
            <Box hidden={tab !== 2} id="tabpanel-2">
              <MyStoreInfo />
            </Box>
          </>
        </LegoSetProvider>
      </Box >
    );
  } else if (getLegoSetState.status === api.error) {
    return (
      <div style={{ width: 800, margin: '0 auto', textAlign: 'center' }}>
        <NotFound />
        <h4><Link href="/catalog">Return to Catalog</Link></h4>
      </div >);
  } else {
    return (<div style={{ marginTop: 32, width: 800 }}><LinearProgress /></div>);
  }
};